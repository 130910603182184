import React, { useState } from 'react'
import { BsEye, BsEyeSlash } from 'react-icons/bs';

const Input = ({
    label,
    type,
    placeholder,
    value,
    onChange = () => {},
    onFocus = () => {},
    error,
    required = false,
    ...props
}) => {

    const [showPassword, setShowPassword] = useState(false);
    const [focused, setOnFoused] = useState(false);
    

    return (
        <div className={`flex flex-col gap-1 flex-1`}>
            {label && <label className='text-sm'>{label} {required && <span>*</span>}</label>}

            <div className={`flex items-center border rounded-lg ${(error && !focused) && 'border-red-500'} ${focused && 'border-primary'} h-[45px]`}>
                <input
                    type={(type === 'password' && showPassword) ? 'text' : type}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    className="flex-1 px-4 py-2 focus:outline-none rounded-lg"
                    // required={required}
                    onFocus={() => {setOnFoused(true); onFocus()}}
                    onBlur={() => setOnFoused(false)}
                    {...props}
                />

                {type === 'password' && (
                    <div className={`cursor-pointer flex justify-center items-center px-4 text-lg`} onClick={() => setShowPassword(curr => !curr)}>
                        {showPassword ? <BsEye /> : <BsEyeSlash />}
                    </div>
                )}
            </div>

            {error && <small className='text-[10px] text-red-500'>{error}</small>}
        </div>
    )
}

export default Input
