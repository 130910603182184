import React, { useEffect, useState } from 'react'
import DataTable from '../../components/DataTable';
import { useApp } from '../../contexts/AppContext';
import { formatDateCourte, formatNumber, isToday } from '../../constants/functions';
import { useAuth } from '../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import { BiEdit, BiPlus } from 'react-icons/bi';
import SelectInput from '../../components/SelectInput';
import Input from '../../components/Input';
import { CgClose } from 'react-icons/cg';
import { BsTicket } from 'react-icons/bs';
import BlockState from '../../components/BlockState';
import { FaBuilding, FaBus, FaMobileAlt, FaRoute, FaTicketAlt, FaUser } from 'react-icons/fa';
import { FaComputer, FaMoneyBillTrendUp } from 'react-icons/fa6';
import { AiOutlineNumber } from 'react-icons/ai';

const ReservationsList = () => {

    const { user } = useAuth();
    const { reservations, agences } = useApp();

    const columns = [
        // { header: "N°", accessor: "index", sortable: true, active: true },
        // { header: "", accessor: "logo", sortable: false },
        { header: "Agence", accessor: "agence", sortable: true, active: user?.profil === "super-admin" },
        { header: "N° Billet", accessor: "numero_billet", sortable: true, active: true },
        { header: "Ligne", accessor: "ligne", sortable: true, active: true },
        { header: "Nom(s)", accessor: "nom", sortable: true, active: true },
        { header: "Prénom(s)", accessor: "prenom", sortable: false, active: true },
        { header: "Téléphone", accessor: "telephone", sortable: false, active: true },
        { header: "Montant (FCFA)", accessor: "montant", sortable: false, active: true },
        { header: "Départ", accessor: "date_depart", sortable: false, active: true },
        { header: "Heure", accessor: "heure_depart", sortable: false, active: true },
        { header: "Source", accessor: "source", sortable: true, active: true },
        // { header: "Statut", accessor: "statut", sortable: false, active: true },
        { header: "Active", accessor: "active", sortable: false, active: false },
        { header: "Création", accessor: "createdAt", sortable: true, active: true },
        { header: "", accessor: "action", sortable: false, active: user?.profil === "caisse" },
    ];

    // Section recherche
    const [inputs, setInputs] = useState({
        query: "",
        agence: "",
        date_debut: "",
        date_fin: "",
        date_filter: "date_depart"
    });

    const handleInputChange = (name, value) => {
        setInputs((prev) => ({ ...prev, [name]: value }));
    };

    const [agenceOption, setAgenceOptions] = useState([]);

    const dateFilterOption = [
        { label: "Date de départ", value: "date_depart" },
        { label: "Date de création", value: "date_creation" }
    ];

    useEffect(() => {
        let list = [
            { value: "", label: "Toutes les agences" }
        ];

        agences.map((a, _) => {
            list.push({
                value: a.id,
                label: a.nom
            })
        })

        setAgenceOptions(list);
    }, [agences]);

    const data = reservations
        .filter(r => {
            // Convertir la date de réservation et les dates de début/fin en objets Date
            const dateCreation = new Date(r.createdAt); // Assure-toi que la date dans `r.date` est un format valide
            const dateReservation = new Date(r.date); // Assure-toi que la date dans `r.date` est un format valide
            const dateDebut = inputs.date_debut ? new Date(inputs.date_debut) : null;
            const dateFin = inputs.date_fin ? new Date(inputs.date_fin) : null;

            // Vérifier si la réservation est dans l'intervalle de dates / date de depart
            const dateDepartInRange = (!dateDebut || dateReservation >= dateDebut) &&
                (!dateFin || dateReservation <= dateFin);


            // Vérifier si la réservation est dans l'intervalle de dates / date de creation
            const dateCreatedInRange = (!dateDebut || dateCreation >= dateDebut) &&
                (!dateFin || dateCreation <= dateFin);

            return inputs.date_filter === "date_depart" ? dateDepartInRange : dateCreatedInRange;
        })
        .filter(r => inputs.agence ? r.ligne_reservation?.agence_id === inputs.agence : true)
        .filter(r => {
            let filter_query = inputs.query?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') || '';
            // Normalisation et suppression des accents de la requête

            // Vérifier si la requête est vide
            if (!filter_query) return true;

            // Fonction pour normaliser et supprimer les accents
            const removeAccents = (str) => str?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') || '';

            // Normaliser les champs à vérifier
            const nom = removeAccents(r.nom);
            const prenom = removeAccents(r.prenom);
            const numero_billet = removeAccents(formatNumber(r.id));
            const telephone = removeAccents(formatNumber(r.telephone));
            const source = removeAccents(formatNumber(r.source));
            const ligne = removeAccents(`${r?.ligne_reservation?.ville_depart_ligne?.code}_${r?.ligne_reservation?.ville_arriee_ligne?.code}`);

            // Filtrer selon le nom, prénom, téléphone, numéro de billet, source, ou ligne
            return nom.includes(filter_query) ||
                prenom.includes(filter_query) ||
                telephone.includes(filter_query) ||
                source.includes(filter_query) ||
                numero_billet.includes(filter_query) ||
                ligne.includes(filter_query);
        })
        .map((item, index) => {

            // verifier si la date de voyage est egale a la date d'aujourd'hui
            const is_able_valid = isToday(item.date);

            const status_embarquement = item?.status_embarquement;

            // console.log(item.id, item)

            return ({
                // index: index + 1,
                agence: item?.ligne_reservation?.agence_ligne?.nom,
                numero_billet: formatNumber(item.id),
                ligne: `${item?.ligne_reservation?.ville_depart_ligne?.code}_${item?.ligne_reservation?.ville_arriee_ligne?.code}`,
                nom: item.nom,
                prenom: item.prenom,
                telephone: item.telephone,
                montant: item.montant_a_payer_total,
                date_depart: formatDateCourte(item.date),
                heure_depart: item?.jour_voyage_reservation?.heure_depart,
                source: `${item?.source}`.toUpperCase(),
                statut: status_embarquement,
                // active: item.active ? "Oui" : "Non",
                createdAt: formatDateCourte(item.createdAt),
                action: <div className='flex-row items-center gap-4'>
                    <Link to={`/reservations/billet/${item.id}`} className='inline-flex justify-center items-center bg-[#121212] text-light p-2 text-lg rounded-lg mr-2'><BsTicket /></Link>
                    <Link to={`/reservations/edit/${item.id}`} className='inline-flex justify-center items-center bg-primary text-light p-2 text-lg rounded-lg ml-2'><BiEdit /></Link>
                </div>
            })
        })
    

    const ussd_data = data.filter(r => r.source === "USSD");
    const mobile_data = data.filter(r => r.source === "Mobile APP");
    const caisse_data = data.filter(r => r.source === "CAISSE");

    const montant_total = data.reduce((acc, item) => acc + item?.montant, 0);
    const montant_mobile = mobile_data.reduce((acc, item) => acc + item?.montant, 0);
    const montant_ussd = ussd_data.reduce((acc, item) => acc + item?.montant, 0);
    const montant_caisse = caisse_data.reduce((acc, item) => acc + item?.montant, 0);


    const blockStateData = [
        {
            value: `${montant_caisse} FCFA`,
            icon: <FaComputer />,  // Icône de billet pour les réservations
            description: `(${caisse_data?.length}/${data?.length}) Réservations faites à la caisse.`,
            path: "",
            color: "#4CAF50",  // Vert pour les réservations effectuées à la caisse
            active: true,
        },
    
        {
            value: `${montant_mobile} FCFA`,
            icon: <FaMobileAlt />,  // Icône de billet pour les réservations
            description: `(${mobile_data?.length}/${data?.length}) Réservations faites depuis l'application mobile.`,
            path: "",
            color: "#2196F3",  // Bleu pour les réservations mobiles
            active: true,
        },
    
        {
            value: `${montant_ussd} FCFA`,
            icon: <AiOutlineNumber />,  // Icône de billet pour les réservations
            description: `(${ussd_data?.length}/${data?.length}) Réservations faites depuis l'USSD MTN.`,
            path: "",
            color: "#FF9800",  // Orange pour les réservations USSD
            active: true,
        },
    
        {
            value: `${montant_total} FCFA`,
            icon: <FaMoneyBillTrendUp />,  // Icône de bâtiment pour les agences
            description: "Total des réservations",
            path: "/",
            color: "#9C27B0",  // Violet pour indiquer le total
            active: user.profil === "super-admin",
        },
    ];
    

    return (
        <div className='flex flex-col gap-4'>

            {/* Entete */}
            <div className='bg-white px-2 py-1 rounded-l'>
                <div className='flex flex-col gap-4'>
                    <div>
                        <h2 className='font-mmedium text-lg'>Reservations</h2>
                        <p className='text-md'>Liste des reservations</p>
                    </div>

                    {(
                        // user?.profil === 'admin' || 
                        user?.profil === 'caisse') && <div className='flex'>
                            <Link to={`/reservations/add`} className='flex items-center gap-2 bg-primary text-light p-2 rounded-lg'>
                                <BiPlus />
                                <span className='text-sm font-mlight'>Enregistrer</span>
                            </Link>
                        </div>}
                </div>

            </div>

            <div className='flex md:flex-row flex-col w-full flex-wrap items-start gap-4'>
                {blockStateData.filter(e => e.active).map((b, index) => {
                    return (
                        <BlockState
                            key={index}
                            value={b.value}
                            icon={b.icon}
                            description={b.description}
                            path={b.path}
                            color={b.color}
                        />
                    )
                })}
            </div>

            {/* Section filtre et recherche */}
            <div className='my-2 bg-white rounded-lg p-2 flex items-end gap-4 flex-col md:flex-row'>
                {user?.profil === "super-admin" && <div className='w-[100%] md:w-[20%] max-w-[100%]'>
                    <SelectInput
                        label="Filtrer par agence"
                        value={inputs.agence}
                        onChange={(e) => handleInputChange('agence', e.value)}

                        options={agenceOption}
                    />
                </div>}

                <div className='w-[100%] md:w-[20%] max-w-[100%]'>
                    <SelectInput
                        label="Filtrer date"
                        value={inputs.date_filter}
                        onChange={(e) => handleInputChange('date_filter', e.value)}

                        options={dateFilterOption}
                    />
                </div>

                <div className='w-[100%] md:w-[20%] max-w-[100%]'>
                    <Input
                        label="De"
                        type="date"
                        value={inputs.date_debut}
                        onChange={(e) => handleInputChange('date_debut', e.target.value)}

                    />
                </div>

                <div className='w-[100%] md:w-[20%] max-w-[100%]'>
                    <Input
                        label="à"
                        type="date"
                        value={inputs.date_fin}
                        onChange={(e) => handleInputChange('date_fin', e.target.value)}

                    />
                </div>

                <div className='w-[100%] flex-1 max-w-[100%]'>
                    <Input
                        label="Recherche"
                        type="text"
                        placeholder="Recherche par numero, nom, prenom, etc..."
                        value={inputs.query}
                        onChange={(e) => handleInputChange('query', e.target.value)}

                    />
                </div>

                {(inputs.query || inputs.agence || inputs.date_debut || inputs.date_fin) && <button
                    className='text-lg text-red-700 cursor-pointer flex justify-center items-center h-[50px] w-[40px]'
                    onClick={() => {
                        handleInputChange("query", "");
                        handleInputChange("agence", "");
                        handleInputChange("date_debut", "");
                        handleInputChange("date_filter", "date_depart");
                        handleInputChange("date_fin", "");
                    }}
                >
                    <CgClose />
                </button>}
            </div>


            <div>
                <DataTable columns={columns.filter(c => c.active)} data={data} />
            </div>
        </div>
    )
}

export default ReservationsList
