import React, { useState } from 'react'
import { BASE_URL } from '../constants/config';

const InputFile = ({
    width,
    icon,
    height,
    label,
    accept,
    placeholder,
    value,
    onChange = () => { },
    onFocus = () => { },
    error,
    required = false,
    ...props
}) => {

    const [focused, setOnFoused] = useState(false);

    // const [defaultValue] = useState(value);

    const eventOnChange = (e) => {
        let file = e.target.files[0]
        onChange(file)
    }

    // useEffect(() => {
    //     setDefaultValue(value);
    // }, [])

    console.log(value.toString().includes('uploads/') ? BASE_URL + value : value)

    return (
        <div className={`flex flex-col gap-1 flex-1`}>
            {label && <span className='text-sm'>{label} {required && <span>*</span>}</span>}

            <label htmlFor={`input-file`} className={`flex border border-dashed rounded-lg cursor-pointer justify-center items-center ${(error && !focused) && 'border-red-500'} ${focused && 'border-primary'}`} style={{ width, height }}>
                {value && <img src={value.toString().includes('uploads/') ? BASE_URL + '/' + value : value} alt='logo actuel' className='w-full h-full object-cover' />}
                {!value && <span className='text-gray-400' style={{ fontSize: width / 2 }}>{icon}</span>}
            </label>
            {/* {label && <label className='text-sm'>{label} {required && <span>*</span>}</label>} */}

            <input
                type='file'
                placeholder={placeholder}
                // value={value}
                onChange={eventOnChange}
                className="flex-1 px-4 py-2 focus:outline-none rounded-lg hidden"
                // required={required}
                onFocus={() => { setOnFoused(true); onFocus() }}
                onBlur={() => setOnFoused(false)}
                id='input-file'
                accept={accept}
                {...props}
            />

            {error && <small className='text-[10px] text-red-500'>{error}</small>}
        </div>
    )
}

export default InputFile
