import React from 'react'
import { useAuth } from '../../contexts/AuthContext';

const Profile = () => {

    const { user, logout } = useAuth();

    return (
        <div>
            <div>{JSON.stringify(user, null, 2)}</div>

            <button onClick={() => logout()}>Logout</button>
        </div>
    )
}

export default Profile
