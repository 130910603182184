import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { AppProvider } from './contexts/AppContext';

import Layouts from './components/Layouts';
import OnLoading from './components/OnLoading';

import Login from './pages/auth/Login';
import Home from './pages/app/Home';
import Profile from './pages/app/Profile';

import AgencesList from './pages/app/AgencesList';
import AgenceAdd from './pages/app/AgenceAdd';
import AgenceEdit from './pages/app/AgenceEdit';
import StationsList from './pages/app/StationsList';
import StationAdd from './pages/app/StationAdd';
import StationEdit from './pages/app/StationEdit';
import LignesList from './pages/app/LignesList';
import LigneAdd from './pages/app/LigneAdd';
import LigneEdit from './pages/app/LigneEdit';
import ReservationsList from './pages/app/ReservationsList';
import ReservationAdd from './pages/app/ReservationAdd';
import ReservationEdit from './pages/app/ReservationEdit';
import VoyagesList from './pages/app/VoyagesList';
import PlanningList from './pages/app/PlanningList';
import PlanningAdd from './pages/app/PlanningAdd';
import PlanningEdit from './pages/app/PlanningEdit';
import VoyageReservationsList from './pages/app/VoyageReservationsList';
import UtilisateursList from './pages/app/UtilisateursList';
import UtilisateurAdd from './pages/app/UtilisateurAdd';
import UtilisateurEdit from './pages/app/UtilisateurEdit';
import ReservationBillet from './pages/app/ReservationBillet';
import AgenceConfiguration from './pages/app/AgenceConfiguration';
import VillesList from './pages/app/VillesList';
import VilleAdd from './pages/app/VilleAdd';
import VilleEdit from './pages/app/VilleEdit';
import ReportingList from './pages/app/ReportingList';



const PrivateRoute = ({ children }) => {
    const { token } = useAuth();
    return token ? children : <Navigate to="/login" />;
};

const AuthRoute = () => {
    const { token } = useAuth();
    return !token ?
        <div className="flex items-center justify-center min-h-screen p-4">
            <Outlet />
        </div> : <Navigate to="/" />;
};

const AppRoute = () => {
    const { onLoading } = useAuth();

    if (onLoading) return <OnLoading />;

    return <div className='bg-gray-100'> <Router>
        <Routes>
            <Route path='/login' element={<AuthRoute />}>
                <Route index element={<Login />} />
            </Route>

            <Route
                path='/'
                element={<PrivateRoute>
                    <Layouts />
                </PrivateRoute>}
            >
                <Route index element={<Home />} />
                <Route path='profile' element={<Profile />} />

                <Route path='agences/list' element={<AgencesList />} />
                <Route path='agences/add' element={<AgenceAdd />} />
                <Route path='agences/edit/:id' element={<AgenceEdit />} />
                <Route path='configurations' element={<AgenceConfiguration />} />

                <Route path='stations/list' element={<StationsList />} />
                <Route path='stations/add' element={<StationAdd />} />
                <Route path='stations/edit/:id' element={<StationEdit />} />

                <Route path='lignes/list' element={<LignesList />} />
                <Route path='lignes/add' element={<LigneAdd />} />
                <Route path='lignes/edit/:id' element={<LigneEdit />} />

                <Route path='planning/list' element={<PlanningList />} />
                <Route path='planning/add' element={<PlanningAdd />} />
                <Route path='planning/edit/:id' element={<PlanningEdit />} />

                <Route path='voyages/list' element={<VoyagesList />} />
                <Route path='voyages/details/list/:id' element={<VoyageReservationsList />} />

                <Route path='reservations/list' element={<ReservationsList />} />
                <Route path='reservations/billet/:id' element={<ReservationBillet />} />
                <Route path='reservations/add' element={<ReservationAdd />} />
                <Route path='reservations/edit/:id' element={<ReservationEdit />} />

                <Route path='utilisateurs/list' element={<UtilisateursList />} />
                <Route path='utilisateurs/add' element={<UtilisateurAdd />} />
                <Route path='utilisateurs/edit/:id' element={<UtilisateurEdit />} />

                <Route path='villes/list' element={<VillesList />} />
                <Route path='villes/add' element={<VilleAdd />} />
                <Route path='villes/edit/:id' element={<VilleEdit />} />

                <Route path='reporting/' element={<ReportingList />} />

            </Route>
        </Routes>
    </Router>
    </div>
}

function App() {
    return (
        <AuthProvider>
            <AppProvider>
                <AppRoute />
            </AppProvider>
        </AuthProvider>
    );
}

export default App;
