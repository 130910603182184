import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const BlockState = ({
    icon,
    value,
    description,
    path,
    color,
    className="w-full flex-1"
}) => {
    return (
        <div className={'flex flex-col gap-4 rounded-lg p-2 ' + className} style={{backgroundColor: color}}>
            <div className='flex justify-between items-center'>
                <span className='text-2xl text-light'>{icon}</span>
                <span className='text-lg text-light font-mmedium'>{value}</span>
            </div>

            <div className='flex justify-between items-center text-light'>
                <p className='font-mregular text-[10px]'>{description}</p>

                <Link to={path} className='text-sm'>
                    <FaArrowCircleRight />
                </Link>
            </div>
        </div>
    )
}

export default BlockState