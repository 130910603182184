import api from './api';

// Pays services
const get_pays = async () => {
    try {
        const response = await api.get(`/pays`);
        return response.data;
    } catch (error) {
        console.log("Erreur lors de la récupération des pays:", error);
        throw error;
    }
};

// Villes services
const get_villes = async () => {
    try {
        const response = await api.get(`/villes`);
        return response.data;
    } catch (error) {
        console.log("Erreur lors de la récupération des villes:", error);
        throw error;
    }
};

const create_ville = async (inputs) => {
    try {
        const response = await api.post(`/villes`, inputs);
        return response.data;
    } catch (error) {
        console.log("Erreur lors de la création de la ville:", error);
        throw error;
    }
};

const update_ville = async (inputs) => {
    try {
        const response = await api.put(`/villes/${inputs.id}`, inputs);
        return response.data;
    } catch (error) {
        console.log("Erreur lors de la mise à jour de la ville:", error);
        throw error;
    }
};

// Agences services
const get_agences = async () => {
    try {
        const response = await api.get(`/agences/web`);
        return response.data;
    } catch (error) {
        console.log("Erreur lors de la récupération des agences:", error);
        throw error;
    }
};

const create_agence = async (inputs) => {
    try {
        const formData = new FormData();
        for (const key in inputs) {
            if (key === 'logo') {
                formData.append(key, Array.isArray(inputs[key]) ? inputs[key][0] : inputs[key]);
            } else {
                formData.append(key, inputs[key]);
            }
        }
        const response = await api.post(`/agences`, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        });
        return response.data;
    } catch (error) {
        console.log("Erreur lors de la création de l'agence:", error);
        throw error;
    }
};

const update_agence = async (inputs) => {
    try {
        const formData = new FormData();
        for (const key in inputs) {
            if (key === 'logo') {
                formData.append(key, Array.isArray(inputs[key]) ? inputs[key][0] : inputs[key]);
            } else {
                formData.append(key, inputs[key]);
            }
        }
        const response = await api.put(`/agences/${inputs.id}`, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        });
        return response.data;
    } catch (error) {
        console.log("Erreur lors de la mise à jour de l'agence:", error);
        throw error;
    }
};

// Stations services
const get_stations = async () => {
    try {
        const response = await api.get(`/stations`);
        return response.data;
    } catch (error) {
        console.log("Erreur lors de la récupération des stations:", error);
        throw error;
    }
};

const create_station = async (inputs) => {
    try {
        const response = await api.post(`/stations`, inputs);
        return response.data;
    } catch (error) {
        console.log("Erreur lors de la création de la station:", error);
        throw error;
    }
};

const update_station = async (inputs) => {
    try {
        const response = await api.put(`/stations/${inputs.id}`, inputs);
        return response.data;
    } catch (error) {
        console.log("Erreur lors de la mise à jour de la station:", error);
        throw error;
    }
};

// Lignes services
const get_lignes = async () => {
    try {
        const response = await api.get(`/lignes`);
        return response.data;
    } catch (error) {
        console.log("Erreur lors de la récupération des lignes:", error);
        throw error;
    }
};

const create_ligne = async (inputs) => {
    try {
        const response = await api.post(`/lignes`, inputs);
        return response.data;
    } catch (error) {
        console.log("Erreur lors de la création de la ligne:", error);
        throw error;
    }
};

const update_ligne = async (inputs) => {
    try {
        const response = await api.put(`/lignes/${inputs.id}`, inputs);
        return response.data;
    } catch (error) {
        console.log("Erreur lors de la mise à jour de la ligne:", error);
        throw error;
    }
};

// Réservations services
const get_reservations = async () => {
    try {
        const response = await api.get(`/reservations`);
        return response.data;
    } catch (error) {
        console.log("Erreur lors de la récupération des réservations:", error);
        throw error;
    }
};

const create_reservation = async (inputs) => {
    try {
        const response = await api.post(`/reservations`, inputs);
        return response.data;
    } catch (error) {
        console.log("Erreur lors de la création de la réservation:", error);
        throw error;
    }
};

const update_reservation = async (inputs) => {
    try {
        const response = await api.put(`/reservations/${inputs.id}`, inputs);
        return response.data;
    } catch (error) {
        console.log("Erreur lors de la mise à jour de la réservation:", error);
        throw error;
    }
};

const embarquement_reservation = async (inputs) => {
    try {
        const response = await api.post(`/reservations/embarquement`, inputs);
        return response.data; 
    } catch (error) {
        console.log("Erreur lors de l'embarquement de la réservation:", error);
        throw error;
    }
};

// Voyages services
const get_voyages = async () => {
    try {
        const response = await api.get(`/voyages`);
        return response.data;
    } catch (error) {
        console.log("Erreur lors de la récupération des voyages:", error);
        throw error;
    }
};

const get_voyages_reservations = async () => {
    try {
        const response = await api.get(`/voyages/reservations`);
        return response.data;
    } catch (error) {
        console.log("Erreur lors de la récupération des reservations voyages:", error);
        throw error;
    }
};

const create_voyage = async (inputs) => {
    try {
        const response = await api.post(`/voyages`, inputs);
        return response.data;
    } catch (error) {
        console.log("Erreur lors de la création du voyage:", error);
        throw error;
    }
};

const update_voyage = async (inputs) => {
    try {
        const response = await api.put(`/voyages/${inputs.id}`, inputs);
        return response.data;
    } catch (error) {
        console.log("Erreur lors de la mise à jour du voyage:", error);
        throw error;
    }
};

// Utilisateurs services
const get_utilisateurs = async () => {
    try {
        const response = await api.get(`/utilisateurs/all`);
        return response.data;
    } catch (error) {
        console.log("Erreur lors de la récupération des utilisateurs:", error);
        throw error;
    }
}; 

const create_utilisateur = async (inputs) => {
    try {
        const response = await api.post(`/utilisateurs/register`, inputs);
        return response.data;
    } catch (error) {
        console.log("Erreur lors de la création de l'utilisateur:", error);
        throw error;
    }
};

const update_utilisateur = async (inputs) => {
    try {
        const response = await api.put(`/utilisateurs/update/${inputs.id}`, inputs);
        return response.data;
    } catch (error) {
        console.log("Erreur lors de la mise à jour de la utilisateur:", error);
        throw error;
    }
};

const appService = {
    get_pays,
    get_villes,
    create_ville,
    update_ville,

    get_agences,
    create_agence,
    update_agence,

    get_stations,
    create_station,
    update_station,

    get_lignes,
    create_ligne,
    update_ligne,

    get_reservations,
    create_reservation,
    update_reservation,
    embarquement_reservation,

    get_voyages,
    get_voyages_reservations,
    create_voyage,
    update_voyage,

    get_utilisateurs,
    create_utilisateur,
    update_utilisateur
};

export default appService;
