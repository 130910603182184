import React, { useEffect, useState } from 'react'
import Input from '../../components/Input';
import SelectInput from '../../components/SelectInput';
import { useNavigate, useParams } from 'react-router-dom';
import { useApp } from '../../contexts/AppContext';
import { useAuth } from '../../contexts/AuthContext';

const ReservationEdit = () => {

  const { user } = useAuth();
  const { update_reservation, lignes, voyages, stations, reservations } = useApp();
  const { id } = useParams();
  const reservation = reservations.find(r => r.id.toString() === id);
  const navigate = useNavigate();

  const defaultElement = {
    agence_id: user?.agence_id,
    utilisateur_id: user?.id,
    source: "caisse",
    nom: "",
    prenom: "",
    telephone: "",
    date: "",
    categorie: 'adulte',
    type_reservation: "aller_simple",
    nombre_place_adulte: 1,
    nombre_place_enfant: 0,
    montant_a_payer_adulte: 0,
    montant_a_payer_enfant: 0,
    montant_a_payer_total: 0,
    ligne_id: "",
    station_depart_id: "",
    station_arrivee_id: "",
    jour_voyage_id: "",
    voyage_id: "",
  };

  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({ agence_id: user?.agence_id, ...reservation } || defaultElement);

  const [errors, setErrors] = useState({});
  const [ligne, setLigne] = useState({});
  const [jourVoyagesOptions, setJourVoyagesOptions] = useState([]);

  const typeReservationOptions = [
    { label: "Aller Simple", value: "aller_simple" },
    { label: "Aller Retour", value: "aller_retour" },
  ];

  const categorieOptions = [
    { label: "Adulte", value: "adulte" },
    { label: "Enfant", value: "enfant" },
  ];

  const handleInputChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleInputChangeError = (name, value) => {
    setErrors((prev) => ({ ...prev, [name]: value }));
  };

  const validate = () => {
    const errors = {};
    let valid = true;

    if (!inputs.nom) {
      errors.nom = 'Le nom de famille est obligatoire';
      valid = false;
    }
    if (!inputs.prenom) {
      errors.prenom = 'Le prénom est obligatoire';
      valid = false;
    }
    if (!inputs.telephone) {
      errors.telephone = 'Le numéro de téléphone est obligatoire';
      valid = false;
    }
    if (!inputs.type_reservation) {
      errors.type_reservation = 'Le type de réservation est obligatoire';
      valid = false;
    }
    if (!inputs.categorie) {
      errors.categorie = 'La catégorie de réservation est obligatoire';
      valid = false;
    }
    if (!inputs.ligne_id) {
      errors.ligne_id = 'La ligne est obligatoire';
      valid = false;
    }
    if (!inputs.date) {
      errors.date = 'La date est obligatoire';
      valid = false;
    }
    if (!inputs.jour_voyage_id) {
      errors.jour_voyage_id = "L'heure de départ est obligatoire";
      valid = false;
    }
    if (!inputs.station_depart_id) {
      errors.station_depart_id = 'La station de départ est obligatoire';
      valid = false;
    }
    if (!inputs.station_arrivee_id) {
      errors.station_arrivee_id = "La station d'arrivée est obligatoire";
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      if (validate()) {
        const response = await update_reservation(inputs);
        if (response) {
          navigate(`/reservations/billet/${response?.id}`);
        }
      }
    } catch (error) {
      console.log("Failed to submit reservation", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    navigate(-1);
  };

  const [lignesOptions, setLignesOptions] = useState([]);
  const [stationDepartOptions, setStationDepartOptions] = useState([]);
  const [stationArriveeOptions, setStationArriveeOptions] = useState([]);

  useEffect(() => {
    const options = lignes.map(l => ({
      value: l.id,
      label: `${l?.ville_depart_ligne?.code}-${l?.ville_arriee_ligne?.code}`
    }));
    setLignesOptions(options);
  }, [lignes]);

  useEffect(() => {
    if (inputs.date && inputs.ligne_id) {
      const joursSemaine = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
      const jourChoisi = joursSemaine[new Date(inputs.date).getDay()];

      const options = voyages
        .filter(v => v.lignes.some(l => l.id === inputs.ligne_id))
        .flatMap(v => v.jours_voyages
          ?.filter(jv => jv.jour === jourChoisi)
          .map(jv => ({
            value: jv.id,
            label: `${jv.heure_depart}`
          }))
        );

      setJourVoyagesOptions(options);
    }
  }, [voyages, inputs.ligne_id, inputs.date]);

  useEffect(() => {
    if (inputs.ligne_id && inputs.jour_voyage_id) {
      const voyageChoisi = voyages.find(v =>
        v.lignes.some(l => l.id === inputs.ligne_id) &&
        v.jours_voyages.some(jv => jv.id === inputs.jour_voyage_id)
      );

      const ligneChoisi = lignes.find(l => l.id === inputs.ligne_id);
      const villeDepartId = ligneChoisi?.ville_depart_ligne?.id;
      const villeArriveeId = ligneChoisi?.ville_arriee_ligne?.id;

      const departOptions = stations
        .filter(s => voyageChoisi?.stations.some(vs => vs.id === s.id) && s.ville_id === villeDepartId)
        .map(s => ({
          value: s.id,
          label: `${s.designation}`
        }));

      const arriveeOptions = stations
        .filter(s => voyageChoisi?.stations.some(vs => vs.id === s.id) && s.ville_id === villeArriveeId)
        .map(s => ({
          value: s.id,
          label: `${s.designation}`
        }));

      handleInputChange("voyage_id", voyageChoisi?.id || "");
      setStationDepartOptions(departOptions);
      setStationArriveeOptions(arriveeOptions);
    }
  }, [voyages, lignes, stations, inputs.ligne_id, inputs.date, inputs.jour_voyage_id]);

  useEffect(() => {
    const ligneChoisi = lignes.find(l => l.id === inputs.ligne_id);
    if (ligneChoisi) {
      const montantAdulte = (inputs.type_reservation === "aller_simple"
        ? (ligneChoisi?.promotion ? ligneChoisi?.prix_promotion_adulte_aller_simple : ligneChoisi?.prix_adulte_aller_simple)
        : (ligneChoisi?.promotion ? ligneChoisi?.prix_promotion_adulte_aller_retour : ligneChoisi?.prix_adulte_aller_retour)) * inputs.nombre_place_adulte;

      const montantEnfant = (inputs.type_reservation === "aller_simple"
        ? (ligneChoisi?.promotion ? ligneChoisi?.prix_promotion_enfant_aller_simple : ligneChoisi?.prix_enfant_aller_simple)
        : (ligneChoisi?.promotion ? ligneChoisi?.prix_promotion_enfant_aller_retour : ligneChoisi?.prix_enfant_aller_retour)) * inputs.nombre_place_enfant;

      const montantTotal = inputs.categorie === "adulte" ? montantAdulte : montantEnfant;

      setLigne(ligneChoisi);
      handleInputChange('montant_a_payer_total', montantTotal);
      handleInputChange('montant_a_payer_adulte', montantAdulte);
      handleInputChange('montant_a_payer_enfant', montantEnfant);
      // handleInputChange('jour_voyage_id', '');
      // handleInputChange('station_depart_id', '');
      // handleInputChange('station_arrivee_id', '');
    }
  }, [inputs.ligne_id, inputs.type_reservation, inputs.nombre_place_adulte, inputs.nombre_place_enfant, inputs.categorie, lignes]);

  useEffect(() => {
    if (inputs.date !== reservation?.date) {
      handleInputChange('jour_voyage_id', '');
      handleInputChange('station_depart_id', '');
      handleInputChange('station_arrivee_id', '');
    }
  }, [inputs.date]);

  useEffect(() => {
    if (inputs.jour_voyage_id !== reservation?.jour_voyage_id) {


      handleInputChange('station_depart_id', '');
      handleInputChange('station_arrivee_id', '');
    }
  }, [inputs.jour_voyage_id]);


  return (
    <div className='flex flex-wrap w-full items-start justify-between'>
      <div className='flex w-[100%] md:w-[100%] mb-4'>
        <form onSubmit={handleSubmit} encType="multipart/form-data" className="space-y-4 flex-1 bg-white p-2 md:p-4 rounded-lg">

          <div className='border-b pb-4 mb-5'>
            <h1 className='font-mmedium text-lg'>Enregistrer une reservations</h1>
            <p className='text-md'>Enregistrer une nouvelle reservation en remplissant le formulaire ci-dessus</p>
          </div>

          <div className='space-y-4'>
            <div className='flex gap-4 md:gap-2 flex-col md:flex-row'>
              <Input
                label="Nom de famille"
                type="text"
                placeholder="Nom de famille"
                value={inputs.nom}
                error={errors.nom}
                onChange={(e) => handleInputChange('nom', e.target.value)}
                onFocus={() => handleInputChangeError('nom', null)}

                required
              />

              <Input
                label="Prénom(s)"
                type="text"
                placeholder="Prénom(s)"
                value={inputs.prenom}
                error={errors.prenom}
                onChange={(e) => handleInputChange('prenom', e.target.value)}
                onFocus={() => handleInputChangeError('prenom', null)}

                required
              />

              <Input
                label="Téléphone"
                type="text"
                placeholder="Téléphone"
                value={inputs.telephone}
                error={errors.telephone}
                onChange={(e) => handleInputChange('telephone', e.target.value)}
                onFocus={() => handleInputChangeError('telephone', null)}

                required
              />
            </div>

            <div className='flex gap-4 md:gap-2 flex-col md:flex-row'>
              <SelectInput
                label="Type de réservation"
                value={inputs.type_reservation}
                onChange={(e) => handleInputChange('type_reservation', e.value)}
                onFocus={() => handleInputChangeError('type_reservation', null)}

                options={typeReservationOptions}
                error={errors.type_reservation}
                required
              />

              <SelectInput
                label="Reservation pour"
                value={inputs.categorie}
                onChange={(e) => {
                  handleInputChange("categorie", e.value);

                  if (e.value === "adulte") {
                    handleInputChange("nombre_place_adulte", 1);
                    handleInputChange("nombre_place_enfant", 0);
                  } else {
                    handleInputChange("nombre_place_adulte", 0);
                    handleInputChange("nombre_place_enfant", 1);
                  }
                }}
                onFocus={() => handleInputChange("categorie", null)}

                options={categorieOptions}
                error={errors.categorie}
                required
              />
            </div>

            <div className='flex gap-4 md:gap-2 flex-col md:flex-row'>
              <SelectInput
                label="Ligne"
                value={inputs.ligne_id}
                onChange={(e) => handleInputChange('ligne_id', e.value)}
                onFocus={() => handleInputChangeError('ligne_id', null)}

                options={lignesOptions}
                error={errors.ligne_id}
                required
              />

              <Input
                label="Date"
                type="date"
                placeholder="Date"
                value={inputs.date ? inputs.date.split('T')[0] : ''}
                error={errors.date}
                onChange={(e) => {
                  handleInputChange('date', e.target.value);
                }}
                onFocus={() => handleInputChangeError('date', null)}
                required
              />

              <SelectInput
                label="Heure de depart"
                value={inputs.jour_voyage_id}
                onChange={(e) => {
                  handleInputChange('jour_voyage_id', e.value);
                }}
                onFocus={() => handleInputChangeError('jour_voyage_id', null)}

                options={jourVoyagesOptions}
                error={errors.jour_voyage_id}
                required
              />

            </div>

            <div className='flex gap-4 md:gap-2 flex-col md:flex-row'>
              <SelectInput
                label="Station de depart"
                value={inputs.station_depart_id}
                onChange={(e) => handleInputChange('station_depart_id', e.value)}
                onFocus={() => handleInputChangeError('station_depart_id', null)}

                options={stationDepartOptions}
                error={errors.station_depart_id}
                required
              />

              <SelectInput
                label="Station d'arrivee"
                value={inputs.station_arrivee_id}
                onChange={(e) => handleInputChange('station_arrivee_id', e.value)}
                onFocus={() => handleInputChangeError('station_arrivee_id', null)}

                options={stationArriveeOptions}
                error={errors.station_arrivee_id}
                required
              />
            </div>



            <div>
              <h3>Montant à payer</h3>
              <div className='flex'>
                <h1 className='text-xl font-mblack text-green-700'>
                  {inputs.montant_a_payer_total || 0} FCFA {ligne?.promotion && <span className='text-[11px] text-black'>(Prix promotionnel au lieux de <span className='text-red-600'>15000 FCFA</span>)</span>}
                </h1>
              </div>
            </div>

          </div>

          <div className='flex justify-end mt-5 gap-2'>
            <button onClick={handleReset} className='flex-1 md:flex-none border border-primary rounded-lg px-4 py-1 text-primary text-md'>Annuler</button>
            <button type='submit' className='flex-1 md:flex-none border border-primary bg-primary rounded-lg px-4 py-1 text-light text-md' disabled={isLoading}>{!isLoading ? 'Enregister' : 'Loading ...'}</button>
          </div>
        </form>
      </div>

      {/* Section billet */}
      {/* <div className='flex flex-col w-[100%] md:w-[29%] bg-white rounded-lg p-2 mb-4'>
        <div>
          <h1>Billet electronique</h1>
        </div>
        <div className='flex-1'></div>
        <div></div>
      </div> */}
    </div>
  )
}

export default ReservationEdit

