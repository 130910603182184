import React from 'react';
import { QRCodeSVG } from 'qrcode.react'; // Importer QRCodeSVG au lieu de QRCode

const QRCodeDisplay = ({ qrValue, size = 128 }) => {
  return (
    <QRCodeSVG
      value={qrValue} // La valeur qui sera encodée dans le code QR
      size={size} // Taille du QR code
      fgColor="black" // Couleur du QR code (foreground)
      bgColor="white" // Couleur de fond (background)
      style={{
        objectFit: 'contain'
      }}
    />
  );
};

export default QRCodeDisplay;
