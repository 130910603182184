import React, { useState } from 'react'
import Input from '../../components/Input';
import SelectInput from '../../components/SelectInput';
import { active_options, type_agence_options } from '../../constants/values';
import Textarea from '../../components/Textarea';
import { useNavigate, useParams } from 'react-router-dom';
import { useApp } from '../../contexts/AppContext';
import InputFile from '../../components/InputFile';
import { BiCamera } from 'react-icons/bi';

const AgenceEdit = () => {
  const { update_agence, agences } = useApp();
  const { id } = useParams();

  const agence = agences.find(a => a.id.toString() === id);

  const defaultElement = {
    nom: "",
    type_agence: "",
    logo: "",
    pourcentage_paiement: 0,
    texte_billet: "Se présenter à l'agence avec ce billet une heure (1h) avant pour les formalités.",
    description: "",
    telephone1: "",
    telephone2: "",
    siteweb: "",
    facebook_link: "",
    adresse: "",
    email: "",
    active: true
  };

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState(agence || defaultElement);

  const [errors, setErrors] = useState({});

  const handleInputChange = (name, value) => {
    setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  const handleInputChangeError = (name, value) => {
    setErrors((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  const validate = () => {
    let valid = true;
    let errors = {};

    if (!inputs.nom) {
      errors.nom = 'Le nom est obligatoire';
      valid = false;
    } else if (/[^a-zA-Z0-9\s]/.test(inputs.nom)) {
      errors.nom = 'Le nom ne doit pas contenir de caractères spéciaux';
      valid = false;
    }

    if (!inputs.type_agence) {
      errors.type_agence = 'Le type d\'agence est obligatoire';
      valid = false;
    }

    if (!inputs.telephone1) {
      errors.telephone1 = 'Le type d\'agence est obligatoire';
      valid = false;
    }

    if (!inputs.email) {
      errors.email = 'L\'adresse email est obligatoire';
      valid = false;
    }

    if (!inputs.logo) {
      errors.logo = 'Le logo est obligatoire';
      valid = false;
    }

    // if (!inputs.telephone || !validatePhoneNumber(countrySelected.regex_mobile, inputs.telephone1)) {
    //     errors.telephone = 'Numero de telephone incorrect';
    //     valid = false;
    // }

    setErrors(errors);
    return valid;
  };


  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      if (validate()) {
        await update_agence(inputs);

        navigate(-1);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("Failed to login", error);
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    navigate(-1);
  }

  return (
    <div className='flex flex-col w-full items-center'>
      <form onSubmit={handleSubmit} encType="multipart/form-data" className="space-y-4 w-[100%] md:min-w-[700px] bg-white p-2 md:p-4 rounded-lg">

        <div className='border-b pb-4 mb-5'>
          <h1 className='font-mmedium text-lg'>Enregistrer une agence</h1>
          <p className='text-md'>Enregistrer une nouvelle agence en remplissant le formulaire ci-dessus</p>
        </div>

        <div className='space-y-4'>


          <InputFile
            width={100}
            height={100}
            label="Logo"
            type="file"
            placeholder="Le logo de l'agence"
            value={inputs.logo}
            error={errors.logo}
            onChange={(e) => handleInputChange('logo', e)}
            onFocus={() => handleInputChangeError('logo', null)}
            required
            accept={'.png, .jpg'}
            icon={<BiCamera />}
          />

          <Input
            label="Nom de l'agence"
            type="text"
            placeholder="Nom de l'agence"
            value={inputs.nom}
            error={errors.nom}
            onChange={(e) => handleInputChange('nom', e.target.value)}
            onFocus={() => handleInputChangeError('nom', null)}

            required
          />


          <div className='flex gap-4 md:gap-2 flex-col md:flex-row'>

            <SelectInput
              label="Type d'agence"
              value={inputs.type_agence}
              onChange={(e) => handleInputChange('type_agence', e.value)}
              onFocus={() => handleInputChangeError('type_agence', null)}

              options={type_agence_options}
              error={errors.type_agence}
              required
            />

            <Input
              label="Pourcentage (%)"
              type="number"
              placeholder="Pourcentage à preleve sur chaque paiment"
              value={inputs.pourcentage_paiement}
              error={errors.pourcentage_paiement}
              onChange={(e) => handleInputChange('pourcentage_paiement', e.target.value)}
              onFocus={() => handleInputChangeError('pourcentage_paiement', null)}
              required
            />

            <SelectInput
              label="Active"
              value={inputs.active}
              onChange={(e) => handleInputChange('active', e.value)}
              onFocus={() => handleInputChangeError('active', null)}

              options={active_options}
              error={errors.active}
              required
            />
          </div>

          <div className='flex gap-4 md:gap-2 flex-col md:flex-row'>
            <Input
              label="Téléphone"
              type="text"
              placeholder="Numéro de téléphone"
              value={inputs.telephone1}
              error={errors.telephone1}
              onChange={(e) => handleInputChange('telephone1', e.target.value)}
              onFocus={() => handleInputChangeError('telephone1', null)}
              required
            />

            <Input
              label="Téléphone 2"
              type="text"
              placeholder="Numéro de téléphone"
              value={inputs.telephone2}
              error={errors.telephone2}
              onChange={(e) => handleInputChange('telephone2', e.target.value)}
              onFocus={() => handleInputChangeError('telephone2', null)}
            />

            <Input
              label="Email"
              type="email"
              placeholder="Adresse electronique"
              value={inputs.email}
              error={errors.email}
              onChange={(e) => handleInputChange('email', e.target.value)}
              onFocus={() => handleInputChangeError('email', null)}
              required
            />
          </div>

          <Input
            label="Adresse"
            type="text"
            placeholder="Adresse de l'agence"
            value={inputs.adresse}
            error={errors.adresse}
            onChange={(e) => handleInputChange('adresse', e.target.value)}
            onFocus={() => handleInputChangeError('adresse', null)}

          />

          <Textarea
            label="Description"
            placeholder="Description de l'agence"
            value={inputs.description}
            error={errors.description}
            onChange={(e) => handleInputChange('description', e.target.value)}
            onFocus={() => handleInputChangeError('description', null)}
          />

          <Textarea
            label="Texte sur le billet"
            placeholder="Texte present sur le billet"
            value={inputs.texte_billet}
            error={errors.texte_billet}
            onChange={(e) => handleInputChange('texte_billet', e.target.value)}
            onFocus={() => handleInputChangeError('texte_billet', null)}
          />
        </div>

        <div className='flex justify-end mt-5 gap-2'>
          <button onClick={handleReset} className='flex-1 md:flex-none border border-primary rounded-lg px-4 py-1 text-primary text-md'>Annuler</button>
          <button type='submit' className='flex-1 md:flex-none border border-primary bg-primary rounded-lg px-4 py-1 text-light text-md' disabled={isLoading}>{!isLoading ? 'Enregister' : 'Loading ...'}</button>
        </div>
      </form>
    </div>
  )
}

export default AgenceEdit