import React from 'react'
import { BiCheck, BiEdit, BiPlus } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import DataTable from '../../components/DataTable';
import { useApp } from '../../contexts/AppContext';
import { BASE_URL } from '../../constants/config';
import { CgClose } from 'react-icons/cg';
import { formatDateCourte } from '../../constants/functions';

const AgencesList = () => {

    const { agences } = useApp();

    const columns = [
        // { header: "N°", accessor: "index", sortable: false },
        // { header: "", accessor: "logo", sortable: false },
        { header: "Nom", accessor: "nom", sortable: true },
        { header: "Type d'agence", accessor: "type_agence", sortable: true },
        { header: "Téléphone", accessor: "telephone1", sortable: false },        
        { header: "Email", accessor: "email", sortable: false },        
        // { header: "Adresse", accessor: "adresse", sortable: false },        
        { header: "%", accessor: "pourcentage_paiement", sortable: false },
        { header: "Active", accessor: "active", sortable: false },
        { header: "Date de création", accessor: "createdAt", sortable: true },
        { header: "", accessor: "action", sortable: false },
    ];

    const data = agences
        .map((item, index) => {

            console.log(`${BASE_URL}/${item.logo}`)

            return ({
                // index: index + 1,
                // logo: <img src={`${BASE_URL}/${item.logo}`} alt={`Logo-${item.nom}`}/>,
                nom: <strong>{item.nom}</strong>,
                type_agence: item.type_agence,
                telephone1: item.telephone1,
                email: item.email,
                // adresse: item.adresse,
                pourcentage_paiement: item.pourcentage_paiement,
                active: item.active ? "Oui" : "Non",
                createdAt: formatDateCourte(item.createdAt),
                action: <Link to={`/agences/edit/${item.id}`} className='flex justify-center items-center bg-primary text-light p-1 text-lg rounded-lg'><BiEdit/></Link>
            })
        })

    // console.log(agences)

    return (
        <div className='flex flex-col gap-4'>
            {/* Entete */}
            <div className='bg-white px-2 py-1 rounded-l'>
                <div className='flex flex-col md:flex-row md:justify-between md:items-center gap-4'>
                    <div>
                        <h2 className='font-mmedium text-lg'>Agences</h2>
                        <p className='text-md'>Liste des agences enregistrees</p>
                    </div>

                    <div className='flex'>
                        <Link to={`/agences/add`} className='flex items-center gap-2 bg-primary text-light p-2 rounded-lg'>
                            <BiPlus />
                            <span className='text-sm font-mlight'>Enregistrer</span>
                        </Link>
                    </div>
                </div>

            </div>

            <div>
                <DataTable columns={columns} data={data} />
            </div>
        </div>
    )
}

export default AgencesList
