import React, { useEffect, useState } from 'react'
import DataTable from '../../components/DataTable';
import { useApp } from '../../contexts/AppContext';
import { formatDateCourte, formatNumber, groupReservations, isToday } from '../../constants/functions';
import { useAuth } from '../../contexts/AuthContext';
import EmbarquementButton from '../../components/EmbarquementButton';
import { Link } from 'react-router-dom';
import { BiCross, BiPlus } from 'react-icons/bi';
import Input from '../../components/Input';
import SelectInput from '../../components/SelectInput';
import { CgClose } from 'react-icons/cg';

const UtilisateursList = () => {

    const { user } = useAuth();
    const { utilisateurs, agences } = useApp();

    const columns = [
        { header: "Nom(s)", accessor: "nom", sortable: true, active: true },
        { header: "Prénom(s)", accessor: "prenom", sortable: true, active: true },
        { header: "Téléphone", accessor: "telephone", sortable: false, active: true },
        { header: "Email", accessor: "email", sortable: false, active: true },
        { header: "Station", accessor: "station", sortable: false, active: user?.profil === "admin" },
        { header: "status", accessor: "status", sortable: false, active: true },

        { header: "", accessor: "action", sortable: false, active: true },
    ];

    // Section recherche
    const [inputs, setInputs] = useState({
        query: "",
        agence: "",
        date_debut: "",
        date_fin: ""
    });

    const handleInputChange = (name, value) => {
        setInputs((prev) => ({ ...prev, [name]: value }));
    };

    const data = utilisateurs
        .filter(u => u.id !== user?.id)
        .filter(u => user?.profil === "super-admin" ? u.profil === "super-admin" : u.profil !== "super-admin")
        .filter(r => {
            let filter_query = inputs.query?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') || '';
            // Normalisation et suppression des accents de la requête

            // Vérifier si la requête est vide
            if (!filter_query) return true;

            // Fonction pour normaliser et supprimer les accents
            const removeAccents = (str) => str?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') || '';

            // Normaliser les champs à vérifier
            const nom = removeAccents(`${r.nom}`);
            const prenom = removeAccents(`${r.prenom}`);
            const telephone = removeAccents(`${r.telephone}`);
            const email = removeAccents(`${r.email}`);
            const station = removeAccents(`${r?.station_utilisateur ? r?.station_utilisateur?.designation : ''}`);
            const status = removeAccents(`${r.active ? 'Actif' : 'Inactif'}`);

            // Filtrer selon le nom, prénom, téléphone, numéro de billet, source, ou ligne
            return nom.includes(filter_query) ||
                prenom.includes(filter_query) ||
                telephone.includes(filter_query) ||
                station.includes(filter_query) ||
                status.includes(filter_query) ||
                email.includes(filter_query);
        })
        .map((item, index) => {
            return ({
                // index: index + 1,
                nom: item.nom,
                prenom: item.prenom,
                telephone: item.telephone,
                email: item.email,
                station: item?.station_utilisateur ? item?.station_utilisateur?.designation : null,
                status: item.active ? 'Actif' : 'Inactif',

                action: <div>
                    <Link to={`/utilisateurs/edit/${item.id}`} className='flex justify-center items-center bg-primary text-light p-1 text-lg rounded-lg'><BiPlus /></Link>
                </div>
            })
        })
    
    console.log(utilisateurs)

    return (
        <div className='flex flex-col gap-4'>
            {/* Entete */}
            <div className='bg-white px-2 py-1 rounded-l'>
                <div className='flex flex-col md:flex-row md:justify-between md:items-center gap-4'>
                    <div>
                        <h2 className='font-mmedium text-lg'>Utilisateurs</h2>
                        <p className='text-md'>Liste des utilisateurs</p>
                    </div>

                    {user?.profil !== 'caisse' && <div className='flex'>
                        <Link to={`/utilisateurs/add`} className='flex items-center gap-2 bg-primary text-light p-2 rounded-lg'>
                            <BiPlus />
                            <span className='text-sm font-mlight'>Enregistrer</span>
                        </Link>
                    </div>}
                </div>
            </div>

            {/* Section filtre et recherche */}
            <div className='my-2 bg-white rounded-lg p-2 flex items-end gap-4 flex-col md:flex-row'>
                {/*{user?.profil === "super-admin" && <div className='w-[100%] md:w-[250px] max-w-[100%]'>
                    <SelectInput
                        label="Filtrer par agence"
                        value={inputs.agence}
                        onChange={(e) => handleInputChange('agence', e.value)}

                        options={agenceOption}
                    />
                </div>}

                 <div className='w-[100%] md:w-[250px] max-w-[100%]'>
                    <Input
                        label="De"
                        type="date"
                        value={inputs.date_debut}
                        onChange={(e) => handleInputChange('date_debut', e.target.value)}

                    />
                </div>

                <div className='w-[100%] md:w-[250px] max-w-[100%]'>
                    <Input
                        label="à"
                        type="date"
                        value={inputs.date_fin}
                        onChange={(e) => handleInputChange('date_fin', e.target.value)}

                    />
                </div> */}

                <div className='w-[100%] flex-1 max-w-[100%]'>
                    <Input
                        label="Recherche"
                        type="text"
                        placeholder="Recherche par nom, prenom, etc..."
                        value={inputs.query}
                        onChange={(e) => handleInputChange('query', e.target.value)}

                    />
                </div>

                {(inputs.query || inputs.agence || inputs.date_debut || inputs.date_fin) && <button
                    className='text-lg text-red-700 cursor-pointer flex justify-center items-center h-[50px] w-[40px]'
                    onClick={() => {
                        handleInputChange("query", "");
                        handleInputChange("agence", "");
                        handleInputChange("date_debut", "");
                        handleInputChange("date_fin", "");
                    }}
                >
                    <CgClose />
                </button>}
            </div>

            <div>
                <DataTable columns={columns.filter(c => c.active)} data={data} />
            </div>
        </div>
    )
}

export default UtilisateursList
