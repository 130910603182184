import React, { useEffect, useState } from 'react'
import DataTable from '../../components/DataTable';
import { useApp } from '../../contexts/AppContext';
import { formatDateCourte, formatNumber, groupReservations, isToday } from '../../constants/functions';
import { useAuth } from '../../contexts/AuthContext';
import EmbarquementButton from '../../components/EmbarquementButton';
import { Link } from 'react-router-dom';
import { BiCross, BiPlus } from 'react-icons/bi';
import Input from '../../components/Input';
import SelectInput from '../../components/SelectInput';
import { CgClose } from 'react-icons/cg';

const VoyagesList = () => {

    const { user } = useAuth();
    const { reservations, agences } = useApp();

    const columns = [
        { header: "Agence", accessor: "agence", sortable: true, active: user?.profil === "super-admin" },
        { header: "Date", accessor: "date", sortable: true, active: true },
        { header: "Heure", accessor: "heure_depart", sortable: false, active: true },
        { header: "Reservations", accessor: "reservations", sortable: false, active: true },

        { header: "", accessor: "action", sortable: false, active: true },
    ];

    // Section recherche
    const [inputs, setInputs] = useState({
        query: "",
        agence: "",
        date_debut: "",
        date_fin: ""
    });

    const handleInputChange = (name, value) => {
        setInputs((prev) => ({ ...prev, [name]: value }));
    };

    const [agenceOption, setAgenceOptions] = useState([]);

    useEffect(() => {
        let list = [
            { value: "", label: "Toutes les agences" }
        ];

        agences.map((a, _) => {
            list.push({
                value: a.id,
                label: a.nom
            })
        })

        setAgenceOptions(list);
    }, [agences]);

    const data = groupReservations(reservations)
        .filter(r => {
            // Convertir la date de réservation et les dates de début/fin en objets Date
            const dateReservation = new Date(r.date); // Assure-toi que la date dans `r.date` est un format valide
            const dateDebut = inputs.date_debut ? new Date(inputs.date_debut) : null;
            const dateFin = inputs.date_fin ? new Date(inputs.date_fin) : null;

            // Vérifier si la réservation est dans l'intervalle de dates
            const dateInRange = (!dateDebut || dateReservation >= dateDebut) &&
                (!dateFin || dateReservation <= dateFin);

            return dateInRange;
        })
        .filter(r => inputs.agence ? r.voyage_info?.agence_id === inputs.agence : true)
        .filter(r => {
            let filter_query = inputs.query?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') || '';
            // Normalisation et suppression des accents de la requête

            // Vérifier si la requête est vide
            if (!filter_query) return true;

            // Fonction pour normaliser et supprimer les accents
            const removeAccents = (str) => str?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') || '';

            // Normaliser les champs à vérifier
            const date = removeAccents(`${formatDateCourte(r.date)}`);
            const agence = removeAccents(`${r.voyage_info?.agence_voyage?.nom}`);
            const heure_depart = removeAccents(`${r.jour_voyage_info?.heure_depart}`);
            const reservations = removeAccents(`${r.reservations?.length}`);

            // Filtrer selon le nom, prénom, téléphone, numéro de billet, source, ou ligne
            return date.includes(filter_query) ||
            agence.includes(filter_query) ||
            heure_depart.includes(filter_query) ||
            reservations.includes(filter_query);
        })
        .map((item, index) => {


            return ({
                // index: index + 1,
                agence: item?.voyage_info?.agence_voyage?.nom,
                date: formatDateCourte(item.date),
                heure_depart: item?.jour_voyage_info?.heure_depart,
                reservations: item?.reservations?.length,

                action: <div>
                    <Link to={`/voyages/details/list/${item.id}`} className='flex justify-center items-center bg-primary text-light p-1 text-lg rounded-lg'><BiPlus /></Link>
                </div>
            })
        })


    return (
        <div className='flex flex-col gap-4'>
            {/* Entete */}
            <div className='bg-white px-2 py-1 rounded-l'>
                <div className='flex flex-col md:flex-row md:justify-between md:items-center gap-4'>
                    <div>
                        <h2 className='font-mmedium text-lg'>Voyages</h2>
                        <p className='text-md'>Liste des voyages</p>
                    </div>
                </div>

            </div>

            {/* Section filtre et recherche */}
            <div className='my-2 bg-white rounded-lg p-2 flex items-end gap-4 flex-col md:flex-row'>
                {user?.profil === "super-admin" && <div className='w-[100%] md:w-[250px] max-w-[100%]'>
                    <SelectInput
                        label="Filtrer par agence"
                        value={inputs.agence}
                        onChange={(e) => handleInputChange('agence', e.value)}

                        options={agenceOption}
                    />
                </div>}

                <div className='w-[100%] md:w-[250px] max-w-[100%]'>
                    <Input
                        label="De"
                        type="date"
                        value={inputs.date_debut}
                        onChange={(e) => handleInputChange('date_debut', e.target.value)}

                    />
                </div>

                <div className='w-[100%] md:w-[250px] max-w-[100%]'>
                    <Input
                        label="à"
                        type="date"
                        value={inputs.date_fin}
                        onChange={(e) => handleInputChange('date_fin', e.target.value)}

                    />
                </div>

                <div className='w-[100%] flex-1 max-w-[100%]'>
                    <Input
                        label="Recherche"
                        type="text"
                        placeholder="Recherche par numero, nom, prenom, etc..."
                        value={inputs.query}
                        onChange={(e) => handleInputChange('query', e.target.value)}

                    />
                </div>

                {(inputs.query || inputs.agence || inputs.date_debut || inputs.date_fin) && <button
                    className='text-lg text-red-700 cursor-pointer flex justify-center items-center h-[50px] w-[40px]'
                    onClick={() => {
                        handleInputChange("query", "");
                        handleInputChange("agence", "");
                        handleInputChange("date_debut", "");
                        handleInputChange("date_fin", "");
                    }}
                >
                    <CgClose/>
                </button>}
            </div>

            <div>
                <DataTable columns={columns.filter(c => c.active)} data={data} />
            </div>
        </div>
    )
}

export default VoyagesList
