import React, { useEffect, useState } from 'react'
import { BiCheck, BiEdit, BiPlus } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import DataTable from '../../components/DataTable';
import { useApp } from '../../contexts/AppContext';
import { BASE_URL } from '../../constants/config';
import { CgClose } from 'react-icons/cg';
import { formatDateCourte } from '../../constants/functions';
import { useAuth } from '../../contexts/AuthContext';
import Input from '../../components/Input';
import SelectInput from '../../components/SelectInput';

const LignesList = () => {

    const { user } = useAuth();
    const { lignes, agences } = useApp();

    const columns = [
        // { header: "N°", accessor: "index", sortable: false },
        // { header: "", accessor: "logo", sortable: false },
        { header: "Agence", accessor: "agence", sortable: true, active: user?.profil === "super-admin" },
        { header: "Designation", accessor: "designation", sortable: true, active: true },
        { header: "Prix adulte (FCFA)", accessor: "prix_adulte_aller_simple", sortable: true, active: true },
        { header: "Prix enfant (FCFA)", accessor: "prix_enfant_aller_simple", sortable: true, active: true },
        // { header: "Jours", accessor: "ligne_jours_voyages", sortable: true, active: true },
        { header: "Promotion", accessor: "promotion", sortable: false, active: true },
        { header: "Active", accessor: "active", sortable: false, active: true },
        // { header: "Date de création", accessor: "createdAt", sortable: true, active: true },
        { header: "", accessor: "action", sortable: false, active: user?.profil === "admin" },
    ];

    // Section recherche
    const [inputs, setInputs] = useState({
        query: "",
        agence: "",
    });

    const handleInputChange = (name, value) => {
        setInputs((prev) => ({ ...prev, [name]: value }));
    };

    const [agenceOption, setAgenceOptions] = useState([]);

    useEffect(() => {
        let list = [
            { value: "", label: "Toutes les agences" }
        ];

        agences.map((a, _) => {
            list.push({
                value: a.id,
                label: a.nom
            })
        })

        setAgenceOptions(list);
    }, [agences]);

    const data = lignes
        .filter(r => inputs.agence ? r.agence_id === inputs.agence : true)
        .filter(r => {
            let filter_query = inputs.query?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') || '';
            // Normalisation et suppression des accents de la requête

            // Vérifier si la requête est vide
            if (!filter_query) return true;

            // Fonction pour normaliser et supprimer les accents
            const removeAccents = (str) => str?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') || '';

            // Normaliser les champs à vérifier
            const date = removeAccents(`${formatDateCourte(r.createdAt)}`);
            const agence = removeAccents(`${r.agence_ligne?.nom}`);
            const designation = removeAccents(`${r.ville_depart_ligne?.code}_${r.ville_arriee_ligne?.code}`);
            const ville = removeAccents(`${r.ville_depart_ligne?.nom}_${r.ville_arriee_ligne?.nom}`);
            const ligne_jours_voyages = removeAccents(`${r.ligne_jours_voyages}`);
            const prix_adulte_aller_simple = removeAccents(`${r.prix_adulte_aller_simple}`);
            const prix_enfant_aller_simple = removeAccents(`${r.prix_enfant_aller_simple}`);
            const promotion = removeAccents(`${r.promotion ? "Oui" : "Non"}`);
            const active = removeAccents(`${r.active ? "Oui" : "Non"}`);

            // Filtrer selon le nom, prénom, téléphone, numéro de billet, source, ou ligne
            return date.includes(filter_query) ||
                agence.includes(filter_query) ||
                designation.includes(filter_query) ||
                ligne_jours_voyages.includes(filter_query) ||
                prix_adulte_aller_simple.includes(filter_query) ||
                prix_enfant_aller_simple.includes(filter_query) ||
                promotion.includes(filter_query) ||
                active.includes(filter_query) ||
                ville.includes(filter_query);
        })
        .map((item, index) => {

            console.log(`${BASE_URL}/${item.logo}`)

            return ({
                // index: index + 1,
                // logo: <img src={`${BASE_URL}/${item.logo}`} alt={`Logo-${item.nom}`}/>,
                agence: item.agence_ligne?.nom,
                designation: `${item.ville_depart_ligne?.code}_${item.ville_arriee_ligne?.code}`,
                ligne_jours_voyages: (item.ligne_jours_voyages?.map((j, _) => {
                    return (<li key={_} className='flex text-sm'>{`${j.jour} (${j.heure_depart}) `}</li>)
                })),
                prix_adulte_aller_simple: item.prix_adulte_aller_simple,
                prix_enfant_aller_simple: item.prix_enfant_aller_simple,
                active: item.active ? "Oui" : "Non",
                promotion: item.promotion ? "Oui" : "Non",
                createdAt: formatDateCourte(item.createdAt),
                action: <Link to={`/lignes/edit/${item.id}`} className='flex justify-center items-center bg-primary text-light p-1 text-lg rounded-lg'><BiEdit /></Link>
            })
        })


    return (
        <div className='flex flex-col gap-4'>
            {/* Entete */}
            <div className='bg-white px-2 py-1 rounded-l'>
                <div className='flex flex-col md:flex-row md:justify-between md:items-center gap-4'>
                    <div>
                        <h2 className='font-mmedium text-lg'>Lignes</h2>
                        <p className='text-md'>Liste des lignes</p>
                    </div>

                    {user?.profil === 'admin' && <div className='flex'>
                        <Link to={`/lignes/add`} className='flex items-center gap-2 bg-primary text-light p-2 rounded-lg'>
                            <BiPlus />
                            <span className='text-sm font-mlight'>Enregistrer</span>
                        </Link>
                    </div>}
                </div>

            </div>

            {/* Section filtre et recherche */}
            <div className='my-2 bg-white rounded-lg p-2 flex items-end gap-4 flex-col md:flex-row'>
                {user?.profil === "super-admin" && <div className='w-[100%] md:w-[250px] max-w-[100%]'>
                    <SelectInput
                        label="Filtrer par agence"
                        value={inputs.agence}
                        onChange={(e) => handleInputChange('agence', e.value)}

                        options={agenceOption}
                    />
                </div>}

                <div className='w-[100%] flex-1 max-w-[100%]'>
                    <Input
                        label="Recherche"
                        type="text"
                        placeholder="Recherche par numero, nom, prenom, etc..."
                        value={inputs.query}
                        onChange={(e) => handleInputChange('query', e.target.value)}

                    />
                </div>

                {(inputs.query || inputs.agence) && <button
                    className='text-lg text-red-700 cursor-pointer flex justify-center items-center h-[50px] w-[40px]'
                    onClick={() => {
                        handleInputChange("query", "");
                        handleInputChange("agence", "");
                    }}
                >
                    <CgClose />
                </button>}
            </div>

            <div>
                <DataTable columns={columns.filter(c => c.active)} data={data} />
            </div>
        </div>
    )
}

export default LignesList
