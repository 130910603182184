import React, { useState, useMemo, useEffect } from 'react';
import DataTable from '../../components/DataTable';
import { useApp } from '../../contexts/AppContext';
import { formatDateCourte, formatNumber, groupReservations, isToday } from '../../constants/functions';
import { useAuth } from '../../contexts/AuthContext';
import EmbarquementButton from '../../components/EmbarquementButton';
import { Link, useParams } from 'react-router-dom';
import { BiEdit, BiPlus, BiSort, BiSortDown, BiSortUp } from 'react-icons/bi';
import SelectInput from '../../components/SelectInput';
import Input from '../../components/Input';
import { CgClose } from 'react-icons/cg';
import { AiOutlineFileExcel, AiOutlineFilePdf } from 'react-icons/ai';
import { CSVLink } from 'react-csv'; // For CSV export
import * as XLSX from 'xlsx'; // For Excel export
import jsPDF from 'jspdf'; // For PDF export
import 'jspdf-autotable'; // For PDF table export

const VoyageReservationsList = () => {
    const { user } = useAuth();
    const { reservations, lignes, voyagesReservations,  } = useApp();
    const { id } = useParams();

    const [voyage, setVoyage] = useState({});
    const [inputs, setInputs] = useState({ query: "" });
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [filteredReservations, setFilteredReservations] = useState([]);
    const [sortedReservations, setSortedReservations] = useState([]);

    const handleInputChange = (name, value) => setInputs(prev => ({ ...prev, [name]: value }));

    const handleSort = (columnAccessor) => {
        let direction = 'asc';
        if (sortConfig.key === columnAccessor && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key: columnAccessor, direction });
    };

    const columns = [
        { header: "Agence", accessor: "agence", sortable: true, active: user?.profil === "super-admin" },
        { header: "N° Billet", accessor: "numero_billet", sortable: true, active: true },
        { header: "Ligne", accessor: "ligne", sortable: true, active: true },
        { header: "Nom(s)", accessor: "nom", sortable: true, active: true },
        { header: "Prénom(s)", accessor: "prenom", sortable: false, active: true },
        { header: "Téléphone", accessor: "telephone", sortable: false, active: true },
        { header: "Montant (FCFA)", accessor: "montant", sortable: false, active: true },
        { header: "Départ", accessor: "date_depart", sortable: false, active: true },
        { header: "Heure", accessor: "heure_depart", sortable: false, active: true },
        { header: "Source", accessor: "source", sortable: true, active: true },
        { header: "Active", accessor: "active", sortable: false, active: false },
        { header: "Date de création", accessor: "createdAt", sortable: true, active: false },
        { header: "", accessor: "action", sortable: false, active: user?.profil === "caisse" },
    ];

    const removeAccents = (str) => str?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') || '';

    useEffect(() => {
        const voyage = groupReservations(reservations).find(v => v.id === id);
        setVoyage(voyage);
    }, [reservations, id]);

    useEffect(() => {
        if (voyage?.reservations) {
            let filteredData = voyage?.reservations;

            // Apply search filter
            if (inputs.query) {
                const filterQuery = removeAccents(inputs.query);
                filteredData = filteredData.filter(r => {
                    const agence = removeAccents(r?.ligne_reservation?.agence_ligne?.nom);
                    const nom = removeAccents(r.nom);
                    const prenom = removeAccents(r.prenom);
                    const numero_billet = removeAccents(formatNumber(r.id));
                    const telephone = removeAccents(r.telephone);
                    const source = removeAccents(r.source);
                    const ligne = removeAccents(`${r?.ligne_reservation?.ville_depart_ligne?.code}_${r?.ligne_reservation?.ville_arriee_ligne?.code}`);

                    return [agence, nom, prenom, numero_billet, telephone, source, ligne].some(field => field.includes(filterQuery));
                });
            }

            setFilteredReservations(filteredData);
        }
    }, [inputs.query, voyage, voyage?.reservations]);

    useEffect(() => {
        if (filteredReservations.length > 0 && sortConfig.key) {
            let sortedData = [...filteredReservations];
            sortedData.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
                if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
                return 0;
            });
            setSortedReservations([...filteredReservations]); 
        } else {
            setSortedReservations(filteredReservations);
        }
    }, [filteredReservations, sortConfig, voyage?.reservations]);
    

    const cleanDataForExport = (data) => {
        return data.map(row => columns.reduce((acc, col) => {
            const value = row[col.accessor];
            acc[col.accessor] = React.isValidElement(value) ? value.props.children || '' : value;
            return acc;
        }, {}));
    };

    const exportToExcel = (data) => {
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "data.xlsx");
    };

    const exportToPDF = (data) => {
        const doc = new jsPDF();
        doc.autoTable({
            head: [columns.map(col => col.header)],
            body: data.map(row => columns.map(col => row[col.accessor])),
        });
        doc.save("data.pdf");
    };


    return (
        <div className='flex flex-col gap-4'>

            <div className='bg-white px-2 py-1 rounded-l'>
                <div className='flex flex-col gap-4'>
                    <div>
                        <h2 className='font-mmedium text-lg'>Réservations</h2>
                        <p className='text-md'>Liste des reservations {user?.profil === "super-admin" ? `"${voyage?.agence?.nom}"` : ''}</p>
                    </div>

                    <div className='border-t pt-4'>
                        <span className='text-sm'>Réservations {voyage?.reservations?.length}/{voyage?.jour_voyage_info?.nombre_de_place} - Lignes {voyage?.reservations_by_ligne?.length}</span>
                        <div className='flex gap-4 flex-wrap'>
                            {voyage?.reservations_by_ligne?.map(vr => {

                                let ligne = lignes.find(l => l.id === vr.ligne_id)

                                return (
                                    <span className='font-mmedium text-md'>{`${ligne?.ville_depart_ligne?.code}-${ligne?.ville_arriee_ligne?.code} : ${vr.reservations}`}</span>
                                )
                            })}
                        </div>
                    </div>
                </div>

            </div>

            <div className='my-2 bg-white rounded-lg p-2 flex items-center gap-4 flex-col md:flex-row'>
                <div className='w-[100%] md:w-[250px] max-w-[100%]'>
                    <Input
                        label="Recherche"
                        type="text"
                        placeholder="Recherche par numero, nom, prenom, etc..."
                        value={inputs.query}
                        onChange={(e) => handleInputChange('query', e.target.value)}
                    />
                </div>

                {inputs.query && <button
                    className='text-lg text-red-700 cursor-pointer flex justify-center items-center h-[50px] w-[40px]'
                    onClick={() => handleInputChange("query", "")}
                >
                    <CgClose />
                </button>}
            </div>

            <div className="relative overflow-x-auto bg-white">
                <div className="flex mb-4 justify-end m-2">
                    <button
                        onClick={() => exportToExcel(cleanDataForExport(sortedReservations))}
                        className="px-4 py-2 bg-green-500 text-white rounded flex items-center mr-2"
                    >
                        <AiOutlineFileExcel />
                    </button>
                    <button
                        onClick={() => exportToPDF(cleanDataForExport(sortedReservations))}
                        className="px-4 py-2 bg-red-500 text-white rounded flex items-center"
                    >
                        <AiOutlineFilePdf />
                    </button>
                </div>

                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            {columns.filter(c => c.active).map((column, index) => (
                                <th
                                    key={index}
                                    className={`px-6 py-3 ${column.sortable ? 'cursor-pointer' : ''}`}
                                    onClick={() => column.sortable && handleSort(column.accessor)}
                                >
                                    <div className="flex items-center">
                                        {column.header}
                                        {column.sortable && (
                                            <>
                                                {sortConfig.key === column.accessor && sortConfig.direction === 'asc' ? (
                                                    <BiSortUp />
                                                ) : sortConfig.key === column.accessor && sortConfig.direction === 'desc' ? (
                                                    <BiSortDown />
                                                ) : (
                                                    <BiSort />
                                                )}
                                            </>
                                        )}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>

                    <tbody>
                        {sortedReservations.length > 0 ? (
                            sortedReservations.map((reservation, index) => {
                                // verifier si la date de voyage est egale a la date d'aujourd'hui
                                const is_able_valid = isToday(reservation.date);
                                const status_embarquement = reservation?.status_embarquement;

                                return (


                                    <tr key={index}>
                                        {user?.profil === "super-admin" && <td className="px-6 py-4 text-center">{reservation?.ligne_reservation?.agence_ligne?.nom}</td>}
                                        <td className="px-6 py-4 text-center">{formatNumber(reservation?.id)}</td>
                                        <td className="px-6 py-4 text-center">{`${reservation?.ligne_reservation?.ville_depart_ligne?.code}_${reservation?.ligne_reservation?.ville_arriee_ligne?.code}`}</td>
                                        <td className="px-6 py-4 text-center">{reservation.nom}</td>
                                        <td className="px-6 py-4 text-center">{reservation.prenom}</td>
                                        <td className="px-6 py-4 text-center">{reservation.telephone}</td>
                                        <td className="px-6 py-4 text-center">{reservation.montant_a_payer_total}</td>
                                        <td className="px-6 py-4 text-center">{formatDateCourte(reservation.date)}</td>
                                        <td className="px-6 py-4 text-center">{reservation.jour_voyage_reservation?.heure_depart}</td>
                                        <td className="px-6 py-4 text-center uppercase">{reservation.source}</td>
                                        {user?.profil === "caisse" && <td>
                                            {is_able_valid && <EmbarquementButton data={reservation} />}
                                        </td>}
                                    </tr>
                                )
                            })
                        ) : (
                            <tr><td colSpan={columns.length} className="px-6 py-4 text-center">Aucune réservation trouvée</td></tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default VoyageReservationsList;







