import { createContext, useContext, useState, useEffect } from 'react';
import appService from '../services/appService';
import { useAuth } from './AuthContext';
import { toast } from 'react-toastify';
import { groupReservations } from '../constants/functions';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const { user } = useAuth();

    const [pays, setPays] = useState([]);
    const [villes, setVilles] = useState([]);
    const [agences, setAgences] = useState([]);
    const [stations, setStations] = useState([]);
    const [lignes, setLignes] = useState([]);
    const [reservations, setReservations] = useState([]);
    const [voyages, setVoyages] = useState([]);
    const [voyagesReservations, setVoyagesReservations] = useState([]);
    const [utilisateurs, setUtilisateurs] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                if (user?.id) {
                    await get_pays();
                    await get_villes();
                    await get_agences();
                    await get_stations();
                    await get_lignes();
                    await get_reservations();
                    await get_voyages();
                    await get_voyages_reservations();
                    await get_utilisateurs();
                }
                return true;
            } catch (err) {
                setError("Une erreur s'est produite lors du chargement des données.");
                console.log(err);
                return false;
                toast.error("Erreur lors du chargement des données");
            }
        })();
    }, [user]);

    // Pays
    const get_pays = async () => {
        try {
            const data = await appService.get_pays();
            setPays(data);
            return true;
        } catch (err) {
            setError(err?.response?.data?.message || "Erreur lors de la récupération des pays");
            toast.error(err?.response?.data?.message || "Erreur lors de la récupération des pays");
            console.log(err);
            return false;
        }
    };

    // Villes
    const get_villes = async () => {
        try {
            const data = await appService.get_villes();
            setVilles(data);
            return true;
        } catch (err) {
            setError(err?.response?.data?.message || "Erreur lors de la récupération des ville");
            toast.error(err?.response?.data?.message || "Erreur lors de la récupération des ville");
            console.log(err);
            return false;
        }
    };

    const create_ville = async (inputs) => {
        try {
            const data = await appService.create_ville(inputs);
            setVilles([...villes, data]);
            toast.success(data.message || "ville créée avec succès");
            return true;
        } catch (err) {
            setError(err?.response?.data?.message || 'Erreur lors de la création de la ville.');
            toast.error(err?.response?.data?.message || "Erreur lors de la création de la ville");
            console.log(err);
            return false;
        }
    };

    const update_ville = async (inputs) => {
        try {
            const data = await appService.update_ville(inputs);
            setVilles(villes.map((e) => (e.id === inputs.id ? data : e)));
            toast.success(data.message || "ville mise à jour avec succès");
            return true;
        } catch (err) {
            setError(err?.response?.data?.message || 'Erreur lors de la mise à jour de la ville.');
            toast.error(err?.response?.data?.message || "Erreur lors de la mise à jour de la ville");
            console.log(err);
            return false;
        }
    };

    // Agences
    const get_agences = async () => {
        try {
            const data = await appService.get_agences();
            setAgences(data);
            // toast.success(data.message || "Agences chargées avec succès");
            return true;
        } catch (err) {
            setError(err?.response?.data?.message || 'Erreur lors de la récupération des agences.');
            toast.error(err?.response?.data?.message || "Erreur lors de la récupération des agences");
            console.log(err);
            return false;
        }
    };

    const create_agence = async (inputs) => {
        try {
            const data = await appService.create_agence(inputs);
            setAgences([...agences, data]);
            toast.success(data.message || "Agence créée avec succès");
            return true;
        } catch (err) {
            setError(err?.response?.data?.message || 'Erreur lors de la création de l\'agence.');
            toast.error(err?.response?.data?.message || "Erreur lors de la création de l'agence");
            console.log(err);
            return false;
        }
    };

    const update_agence = async (inputs) => {
        try {
            const data = await appService.update_agence(inputs);
            setAgences(agences.map((e) => (e.id === inputs.id ? data : e)));
            toast.success(data.message || "Agence mise à jour avec succès");
            return data;
        } catch (err) {
            setError(err?.response?.data?.message || 'Erreur lors de la mise à jour de l\'agence.');
            toast.error(err?.response?.data?.message || "Erreur lors de la mise à jour de l'agence");
            console.log(err);
            return false;
        }
    };

    // Stations
    const get_stations = async () => {
        try {
            const data = await appService.get_stations();
            setStations(data);
            // toast.success(data.message || "Stations chargées avec succès");
            return true;
        } catch (err) {
            setError(err?.response?.data?.message || 'Erreur lors de la récupération des stations.');
            toast.error(err?.response?.data?.message || "Erreur lors de la récupération des stations");
            console.log(err);
            return false;
        }
    };

    const create_station = async (inputs) => {
        try {
            const data = await appService.create_station(inputs);
            setStations([...stations, data]);
            toast.success(data.message || "Station créée avec succès");
            return true;
        } catch (err) {
            setError(err?.response?.data?.message || 'Erreur lors de la création de la station.');
            toast.error(err?.response?.data?.message || "Erreur lors de la création de la station");
            console.log(err);
            return false;
        }
    };

    const update_station = async (inputs) => {
        try {
            const data = await appService.update_station(inputs);
            setStations(stations.map((e) => (e.id === inputs.id ? data : e)));
            toast.success(data.message || "Station mise à jour avec succès");
            return true;
        } catch (err) {
            setError(err?.response?.data?.message || 'Erreur lors de la mise à jour de la station.');
            toast.error(err?.response?.data?.message || "Erreur lors de la mise à jour de la station");
            console.log(err);
            return false;
        }
    };

    // Lignes
    const get_lignes = async () => {
        try {
            const data = await appService.get_lignes();
            setLignes(data);
            // toast.success(data.message || "Lignes chargées avec succès");
            return true;
        } catch (err) {
            setError(err?.response?.data?.message || 'Erreur lors de la récupération des lignes.');
            toast.error(err?.response?.data?.message || "Erreur lors de la récupération des lignes");
            console.log(err);
            return false;
        }
    };

    const create_ligne = async (inputs) => {
        try {
            const data = await appService.create_ligne(inputs);
            setLignes([...lignes, data]);
            toast.success(data.message || "Ligne créée avec succès");
            return true;
        } catch (err) {
            setError(err?.response?.data?.message || 'Erreur lors de la création de la ligne.');
            toast.error(err?.response?.data?.message || "Erreur lors de la création de la ligne");
            console.log(err);
            return false;
        }
    };

    const update_ligne = async (inputs) => {
        try {
            const data = await appService.update_ligne(inputs);
            setLignes(lignes.map((e) => (e.id === inputs.id ? data : e)));
            toast.success(data.message || "Ligne mise à jour avec succès");
            return true;
        } catch (err) {
            setError(err?.response?.data?.message || 'Erreur lors de la mise à jour de la ligne.');
            toast.error(err?.response?.data?.message || "Erreur lors de la mise à jour de la ligne");
            console.log(err);
            return false;
        }
    };

    // Réservations
    const get_reservations = async () => {
        try {
            const data = await appService.get_reservations();
            
            setReservations(data);
            setVoyagesReservations(groupReservations(data));

            // toast.success(data.message || "Réservations chargées avec succès");
            return true;
        } catch (err) {
            setError(err?.response?.data?.message || 'Erreur lors de la récupération des réservations.');
            toast.error(err?.response?.data?.message || "Erreur lors de la récupération des réservations");
            console.log(err);
            return false;
        }
    };

    const create_reservation = async (inputs) => {
        try {
            const data = await appService.create_reservation(inputs);
            setReservations([...reservations, data]);
            setVoyagesReservations(groupReservations([...reservations, data]));
            toast.success(data.message || "Réservation créée avec succès");
            return data;
        } catch (err) {
            setError(err?.response?.data?.message || 'Erreur lors de la création de la réservation.');
            toast.error(err?.response?.data?.message || "Erreur lors de la création de la réservation");
            console.log(err);
            return false;
        }
    };

    const update_reservation = async (inputs) => {
        try {
            const data = await appService.update_reservation(inputs);
            const reservations = reservations.map((e) => (e.id === inputs.id ? data : e));

            setReservations(reservations);
            setVoyagesReservations(groupReservations(reservations));

            toast.success(data.message || "Réservation mise à jour avec succès");
            return data;
        } catch (err) {
            setError(err?.response?.data?.message || 'Erreur lors de la mise à jour de la réservation.');
            toast.error(err?.response?.data?.message || "Erreur lors de la mise à jour de la réservation");
            console.log(err);
            return false;
        }
    };

    const embarquement_reservation = async (inputs) => {
        try {
            const data = await appService.embarquement_reservation(inputs);
            const reservation_voyage = reservations.map((e) => (e.id === inputs.id ? data.reservation : e));

            setReservations(reservation_voyage);
            setVoyagesReservations(groupReservations(reservation_voyage));
            
            toast.success(data.message || "Réservation embarquée avec succès");

            return {value: data.reservation?.status_embarquement, success: true};
        } catch (err) {
            setError(err?.response?.data?.message || 'Erreur lors de l\'embarquement de la réservation.');
            toast.error(err?.response?.data?.message || "Erreur lors de l'embarquement de la réservation");
            console.log(err);
            return {value: null, success: false};
        }
    };

    // Voyages
    const get_voyages = async () => {
        try {
            const data = await appService.get_voyages();
            setVoyages(data);
            // toast.success(data.message || "Voyages chargés avec succès");
            return true;
        } catch (err) {
            setError(err?.response?.data?.message || 'Erreur lors de la récupération des voyages.');
            toast.error(err?.response?.data?.message || "Erreur lors de la récupération des voyages");
            console.log(err);
            return false;
        }
    };

    const get_voyages_reservations = async () => {
        try {
            const data = await appService.get_voyages_reservations();
            // setVoyagesReservations(data);
            // toast.success(data.message || "Voyages avec réservations chargés avec succès");
            return true;
        } catch (err) {
            setError(err?.response?.data?.message || 'Erreur lors de la récupération des voyages avec réservations.');
            toast.error(err?.response?.data?.message || "Erreur lors de la récupération des voyages avec réservations");
            console.log(err);
            return false;
        }
    };

    const create_voyage = async (inputs) => {
        try {
            const data = await appService.create_voyage(inputs);
            setVoyages([...voyages, data]);
            toast.success(data.message || "Voyage créé avec succès");
            return true;
        } catch (err) {
            setError(err?.response?.data?.message || 'Erreur lors de la création du voyage.');
            toast.error(err?.response?.data?.message || "Erreur lors de la création du voyage");
            console.log(err);
            return false;
        }
    };

    const update_voyage = async (inputs) => {
        try {
            const data = await appService.update_voyage(inputs);
            setVoyages(voyages.map((e) => (e.id === inputs.id ? data : e)));
            toast.success(data.message || "Voyage mis à jour avec succès");
            return true;
        } catch (err) {
            setError(err?.response?.data?.message || 'Erreur lors de la mise à jour du voyage.');
            toast.error(err?.response?.data?.message || "Erreur lors de la mise à jour du voyage");
            console.log(err);
            return false;
        }
    };

    // Utilisateurs
    const get_utilisateurs = async () => {
        try {
            const data = await appService.get_utilisateurs();
            setUtilisateurs(data);
            // toast.success(data.message || "Utilisateurs chargés avec succès");
            return true;
        } catch (err) {
            setError(err?.response?.data?.message || 'Erreur lors de la récupération des utilisateurs.');
            toast.error(err?.response?.data?.message || "Erreur lors de la récupération des utilisateurs");
            console.log(err);
            return false;
        }
    };

    const create_utilisateur = async (inputs) => {
        try {
            const data = await appService.create_utilisateur(inputs);
            setUtilisateurs([...utilisateurs, data.user]);
            toast.success(data.message || "Utilisateur créé avec succès");
            return true;
        } catch (err) {
            setError(err?.response?.data?.message || 'Erreur lors de la création de l\'utilisateur.');
            toast.error(err?.response?.data?.message || "Erreur lors de la création de l'utilisateur");
            console.log(err);
            return false;
        }
    };

    const update_utilisateur = async (inputs) => {
        try {
            // Appel du service pour mettre à jour l'utilisateur
            const updatedData = await appService.update_utilisateur(inputs);
            
            // Mise à jour de la liste des utilisateurs en remplaçant l'utilisateur mis à jour
            setUtilisateurs((prevUtilisateurs) => 
                prevUtilisateurs.map((user) => 
                    user.id === inputs.id ? updatedData.user : user
                )
            );
            
            // Notification de succès
            toast.success(updatedData.message || "Utilisateur mis à jour avec succès");
            return true;
        } catch (error) {
            // Gestion des erreurs et affichage d'un message d'erreur
            const errorMessage = error?.response?.data?.message || "Erreur lors de la mise à jour de l'utilisateur";
            setError(errorMessage);
            toast.error(errorMessage);
            console.error(error);
            return false;
        }
    };
    
    return (
        <AppContext.Provider value={{
            pays,
            villes,
            agences,
            stations,
            lignes,
            reservations,
            voyages,
            voyagesReservations,
            utilisateurs, 
            get_villes,
            create_ville,
            update_ville,
            get_agences,
            create_agence,
            update_agence,
            get_stations,
            create_station,
            update_station,
            get_lignes,
            create_ligne,
            update_ligne,
            get_reservations,
            create_reservation,
            update_reservation,
            embarquement_reservation,
            get_voyages,
            get_voyages_reservations,
            create_voyage,
            update_voyage,
            get_utilisateurs,
            create_utilisateur,
            update_utilisateur,
        }}>
            {children}
        </AppContext.Provider>
    );
};

export const useApp = () => {
    return useContext(AppContext);
};
