import React, { useEffect, useState } from 'react'
import Input from '../../components/Input';
import SelectInput from '../../components/SelectInput';
import { active_options, jours_options } from '../../constants/values';
import { useNavigate, useParams } from 'react-router-dom';
import { useApp } from '../../contexts/AppContext';
import { BiPlus } from 'react-icons/bi';
import { useAuth } from '../../contexts/AuthContext';
import { CgClose } from 'react-icons/cg';

const LigneEdit = () => {
    const { lignes, villes, update_ligne } = useApp();
    const { user } = useAuth();
    const { id } = useParams();

    const ligne = lignes.find(a => a.id.toString() === id);

    const defaultElement = {
        agence_id: user?.agence_id,
        ville_depart_id: '',
        ville_arriee_id: '',
        active: true,

        prix_adulte_aller_simple: 0,
        prix_enfant_aller_simple: 0,
        prix_adulte_aller_retour: 0,
        prix_enfant_aller_retour: 0,

        promotion: false,

        prix_promotion_adulte_aller_simple: 0,
        prix_promotion_enfant_aller_simple: 0,
        prix_promotion_adulte_aller_retour: 0,
        prix_promotion_enfant_aller_retour: 0,

        ligne_jours_voyages: []
    };

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [inputs, setInputs] = useState(ligne || defaultElement);

    const [errors, setErrors] = useState({});

    const handleInputChange = (name, value) => {
        setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
    };

    const handleInputChangeError = (name, value) => {
        setErrors((prevInputs) => ({ ...prevInputs, [name]: value }));
    };

    const validate = () => {
        let valid = true;
        let errors = {};

        if (!inputs.ville_depart_id) {
            errors.ville_depart_id = 'La ville de pépart est obligatoire';
            valid = false;
        }

        if (!inputs.ville_arriee_id) {
            errors.ville_arriee_id = 'La ville d\'arrivée est obligatoire';
            valid = false;
        } else if (inputs.ville_arriee_id === inputs.ville_depart_id) {
            errors.ville_arriee_id = 'La ville d\'arrivée doit etre differente de celle de depart';
            valid = false;
        }


        setErrors(errors);
        return valid;
    };


    const handleSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        try {
            if (validate()) {
                await update_ligne(inputs);

                navigate(-1);
            }
            setIsLoading(false);
        } catch (error) {
            console.log("Failed to login", error);
            setIsLoading(false);
        }
    };

    const handleReset = () => {
        navigate(-1);
    }

    const [villes_options, setVilleOptions] = useState([]);

    const handleAddHoraire = () => {
        setInputs((prev) => ({
            ...prev,
            ligne_jours_voyages: [...prev.ligne_jours_voyages, { active: true, heure_depart: '', jour: '' }]
        }));
    };

    const handleJourChange = (index, field, value) => {
        const updatedJours = inputs.ligne_jours_voyages.map((jour, i) =>
            i === index ? { ...jour, [field]: value } : jour
        );
        setInputs((prev) => ({ ...prev, ligne_jours_voyages: updatedJours }));
    };

    const handleRemoveHoraire = (index) => {
        const updatedJours = inputs.ligne_jours_voyages.filter((_, i) => i !== index);
        setInputs((prev) => ({ ...prev, ligne_jours_voyages: updatedJours }));
    };


    useEffect(() => {

        let list = [];

        villes.map((ville, _) => {
            list.push({
                value: ville.id,
                label: ville.nom
            })
        });

        setVilleOptions(list);
    }, [villes])

    return (
        <div className='flex flex-col w-full items-center'>
            <form onSubmit={handleSubmit} encType="multipart/form-data" className="space-y-4 w-[100%] md:min-w-[700px] bg-white p-2 md:p-4 rounded-lg">

                <div className='border-b pb-4 mb-5'>
                    <h1 className='font-mmedium text-lg'>Enregistrer une ligne</h1>
                    <p className='text-md'>Enregistrer une nouvelle ligne en remplissant le formulaire ci-dessus</p>
                </div>

                <div className='space-y-4'>

                    <div className='flex gap-4 md:gap-2 flex-col md:flex-row'>
                        <SelectInput
                            label="Ville de départ"
                            value={inputs.ville_depart_id}
                            onChange={(e) => handleInputChange('ville_depart_id', e.value)}
                            onFocus={() => handleInputChangeError('ville_depart_id', null)}

                            options={villes_options}
                            error={errors.ville_depart_id}
                            required
                        />

                        <SelectInput
                            label="Ville d'arrivée"
                            value={inputs.ville_arriee_id}
                            onChange={(e) => handleInputChange('ville_arriee_id', e.value)}
                            onFocus={() => handleInputChangeError('ville_arriee_id', null)}

                            options={villes_options.filter(v => v.value !== inputs.ville_depart_id)}
                            error={errors.ville_arriee_id}
                            required
                        />

                        <SelectInput
                            label="Active"
                            value={inputs.active}
                            onChange={(e) => handleInputChange('active', e.value)}
                            onFocus={() => handleInputChangeError('active', null)}

                            options={active_options}
                            error={errors.active}
                            required
                        />
                    </div>

                    <div className='flex gap-4 md:gap-2 flex-col md:flex-row'>
                        <Input
                            label="Prix adulte aller simple (FCFA)"
                            type="number"
                            placeholder="Prix adulte aller simple"
                            value={inputs.prix_adulte_aller_simple}
                            error={errors.prix_adulte_aller_simple}
                            onChange={(e) => handleInputChange('prix_adulte_aller_simple', e.target.value)}
                            onFocus={() => handleInputChangeError('prix_adulte_aller_simple', null)}

                            required
                        />

                        <Input
                            label="Prix enfant aller simple (FCFA)"
                            type="number"
                            placeholder="Prix enfant aller simple"
                            value={inputs.prix_enfant_aller_simple}
                            error={errors.prix_enfant_aller_simple}
                            onChange={(e) => handleInputChange('prix_enfant_aller_simple', e.target.value)}
                            onFocus={() => handleInputChangeError('prix_enfant_aller_simple', null)}

                            required
                        />
                    </div>

                    <div className='flex gap-4 md:gap-2 flex-col md:flex-row'>
                        <Input
                            label="Prix adulte aller retour (FCFA)"
                            type="number"
                            placeholder="Prix adulte aller retour"
                            value={inputs.prix_adulte_aller_retour}
                            error={errors.prix_adulte_aller_retour}
                            onChange={(e) => handleInputChange('prix_adulte_aller_retour', e.target.value)}
                            onFocus={() => handleInputChangeError('prix_adulte_aller_retour', null)}

                            required
                        />

                        <Input
                            label="Prix enfant aller retour (FCFA)"
                            type="number"
                            placeholder="Prix enfant aller retour"
                            value={inputs.prix_enfant_aller_retour}
                            error={errors.prix_enfant_aller_retour}
                            onChange={(e) => handleInputChange('prix_enfant_aller_retour', e.target.value)}
                            onFocus={() => handleInputChangeError('prix_enfant_aller_retour', null)}

                            required
                        />
                    </div>


                    <div className='flex items-center justify-between gap-4 border p-4 rounded-lg'>
                        <div>
                            <h3 className='font-mmedium text-sm'>Information sur la promotion</h3>
                            <p className='text-sm font-mlight'>Activer/Desactiver la promotion sur cette ligne en cliquant sur l'icone</p>
                        </div>

                        <view onClick={() => handleInputChange("promotion", !inputs.promotion)} className={`${inputs.promotion ? 'bg-green-700' : 'bg-red-700'} flex cursor-pointer w-[30px] h-[30px] min-w-[30px] min-h-[30px] rounded-full justify-center items-center text-light text-[12px]`}>
                            {inputs.promotion ? 'On' : "Off"}
                        </view>
                    </div>

                    {inputs.promotion && <div>
                        <div className='flex gap-4 md:gap-2 flex-col md:flex-row'>
                            <Input
                                label="Prix adulte aller simple (FCFA)"
                                type="number"
                                placeholder="Prix adulte aller simple"
                                value={inputs.prix_promotion_adulte_aller_simple}
                                error={errors.prix_promotion_adulte_aller_simple}
                                onChange={(e) => handleInputChange('prix_promotion_adulte_aller_simple', e.target.value)}
                                onFocus={() => handleInputChangeError('prix_promotion_adulte_aller_simple', null)}

                                required
                            />

                            <Input
                                label="Prix enfant aller simple (FCFA)"
                                type="number"
                                placeholder="Prix enfant aller simple"
                                value={inputs.prix_promotion_enfant_aller_simple}
                                error={errors.prix_promotion_enfant_aller_simple}
                                onChange={(e) => handleInputChange('prix_promotion_enfant_aller_simple', e.target.value)}
                                onFocus={() => handleInputChangeError('prix_promotion_enfant_aller_simple', null)}

                                required
                            />
                        </div>

                        <div className='flex gap-4 md:gap-2 flex-col md:flex-row'>
                            <Input
                                label="Prix adulte aller retour (FCFA)"
                                type="number"
                                placeholder="Prix adulte aller retour"
                                value={inputs.prix_promotion_adulte_aller_retour}
                                error={errors.prix_promotion_adulte_aller_retour}
                                onChange={(e) => handleInputChange('prix_promotion_adulte_aller_retour', e.target.value)}
                                onFocus={() => handleInputChangeError('prix_promotion_adulte_aller_retour', null)}

                                required
                            />

                            <Input
                                label="Prix enfant aller retour (FCFA)"
                                type="number"
                                placeholder="Prix enfant aller retour"
                                value={inputs.prix_promotion_enfant_aller_retour}
                                error={errors.prix_promotion_enfant_aller_retour}
                                onChange={(e) => handleInputChange('prix_promotion_enfant_aller_retour', e.target.value)}
                                onFocus={() => handleInputChangeError('prix_promotion_enfant_aller_retour', null)}

                                required
                            />
                        </div>
                    </div>}


                </div>

                <div className='flex justify-end mt-5 gap-2'>
                    <button onClick={handleReset} className='flex-1 md:flex-none border border-primary rounded-lg px-4 py-1 text-primary text-md'>Annuler</button>
                    <button type='submit' className='flex-1 md:flex-none border border-primary bg-primary rounded-lg px-4 py-1 text-light text-md' disabled={isLoading}>{!isLoading ? 'Enregister' : 'Loading ...'}</button>
                </div>
            </form>
        </div>
    )
}

export default LigneEdit

