import React, { useEffect, useState } from 'react'
import Input from '../../components/Input';
import SelectInput from '../../components/SelectInput';
import { useNavigate } from 'react-router-dom';
import { useApp } from '../../contexts/AppContext';
import { useAuth } from '../../contexts/AuthContext';

const UtilisateurAdd = () => {

    const { user } = useAuth();
    const { create_utilisateur, stations } = useApp();

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [inputs, setInputs] = useState({
        agence_id: user?.profil === "admin" ? user?.agence_id : null,
        station_id: user?.profil === "admin" ? '' : null,
        profil: user?.profil === "admin" ? "caisse" : user?.profil,
        nom: "",
        prenom: "",
        telephone: "",
        email: "",
        active: true
    });

    const [errors, setErrors] = useState({});

    const handleInputChange = (name, value) => {
        setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
    };

    const handleInputChangeError = (name, value) => {
        setErrors((prevInputs) => ({ ...prevInputs, [name]: value }));
    };

    const validate = () => {
        let valid = true;
        let errors = {};

        if (!inputs.nom) {
            errors.nom = 'La nom est obligatoire';
            valid = false;
        }

        if (!inputs.prenom) {
            errors.prenom = 'La prénom est obligatoire';
            valid = false;
        }

        if (!inputs.email) {
            errors.email = 'L\'adresse email est obligatoire';
            valid = false;
        }

        if (!inputs.telephone) {
            errors.telephone = 'Le numéro de téléphone est obligatoire';
            valid = false;
        }

        if (!inputs.station_id && user?.profil === "admin") {
            errors.station_id = 'La station est obligatoire';
            valid = false;
        }

        setErrors(errors);
        return valid;
    };


    const handleSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        try {
            if (validate()) {
                const response = await create_utilisateur(inputs);

                if(response) return navigate(-1); 
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    const handleReset = () => {
        navigate(-1);
    }

    const status_options = [
        {value: true, label: "Actif"},
        {value: false, label: "Inactif"},
    ]

    const [stations_options, setStationOptions] = useState([]);

    useEffect(() => {

        let list = [];

        stations.map((station, _) => {
            list.push({
                value: station.id,
                label: station.designation
            })
        });

        setStationOptions(list);
    }, [stations])


    return (
        <div className='flex flex-col w-full items-center'>
            <form onSubmit={handleSubmit} encType="multipart/form-data" className="space-y-4 w-[100%] md:min-w-[700px] bg-white p-2 md:p-4 rounded-lg">

                <div className='border-b pb-4 mb-5'>
                    <h1 className='font-mmedium text-lg'>Enregistrer un utilisateur</h1>
                    <p className='text-md'>Enregistrer un nouveau utilisateur en remplissant le formulaire ci-dessus</p>
                </div>

                <div className='space-y-4'>
                    <div className='flex gap-4 md:gap-2 flex-col md:flex-row'>
                        <Input
                            label="Nom"
                            type="text"
                            placeholder="Nom de famille"
                            value={inputs.nom}
                            error={errors.nom}
                            onChange={(e) => handleInputChange('nom', e.target.value)}
                            onFocus={() => handleInputChangeError('nom', null)}

                            required
                        />

                        <Input
                            label="Prénom"
                            type="text"
                            placeholder="Prénom(s)"
                            value={inputs.prenom}
                            error={errors.prenom}
                            onChange={(e) => handleInputChange('prenom', e.target.value)}
                            onFocus={() => handleInputChangeError('prenom', null)}

                            required
                        />
                    </div>

                    <div className='flex gap-4 md:gap-2 flex-col md:flex-row'>
                        <Input
                            label="Téléphone"
                            type="text"
                            placeholder="Numero de téléphone"
                            value={inputs.telephone}
                            error={errors.telephone}
                            onChange={(e) => handleInputChange('telephone', e.target.value)}
                            onFocus={() => handleInputChangeError('telephone', null)}

                            required
                        />

                        <Input
                            label="Email"
                            type="text"
                            placeholder="Adresse email valide"
                            value={inputs.email}
                            error={errors.email}
                            onChange={(e) => handleInputChange('email', e.target.value)}
                            onFocus={() => handleInputChangeError('email', null)}

                            required
                        />

                        {user?.profil !== "admin" && <SelectInput
                            label="Status"
                            value={inputs.active}
                            onChange={(e) => handleInputChange('active', e.value)}
                            onFocus={() => handleInputChangeError('active', null)}

                            options={status_options}
                            error={errors.active}
                            required
                        />}
                    </div>

                    {user?.profil === "admin" && <div className='flex gap-4 md:gap-2 flex-col md:flex-row'>
                        <SelectInput
                            label="Status"
                            value={inputs.active}
                            onChange={(e) => handleInputChange('active', e.value)}
                            onFocus={() => handleInputChangeError('active', null)}

                            options={status_options}
                            error={errors.active}
                            required
                        />

                        <SelectInput
                            label="Station"
                            value={inputs.station_id}
                            onChange={(e) => handleInputChange('station_id', e.value)}
                            onFocus={() => handleInputChangeError('station_id', null)}

                            options={stations_options}
                            error={errors.station_id}
                            required
                        />
                    </div>}
                </div>
                

                <div className='flex justify-end mt-5 gap-2'>
                    <button onClick={handleReset} className='flex-1 md:flex-none border border-primary rounded-lg px-4 py-1 text-primary text-md'>Annuler</button>
                    <button type='submit' className='flex-1 md:flex-none border border-primary bg-primary rounded-lg px-4 py-1 text-light text-md' disabled={isLoading}>{!isLoading ? 'Enregister' : 'Loading ...'}</button>
                </div>
            </form>
        </div>
    )
}

export default UtilisateurAdd

