import React, { useEffect, useState } from 'react'
import { formatDateCourte, formatNumber, groupReservations, isDateInFuture, isToday } from '../../constants/functions';
import { useAuth } from '../../contexts/AuthContext';
import { useApp } from '../../contexts/AppContext';
import EmbarquementButton from '../../components/EmbarquementButton';
import DataTable from '../../components/DataTable';
import BlockState from '../../components/BlockState';
import { FaBreadSlice } from 'react-icons/fa6';
import { FaTicketAlt, FaBus, FaBuilding, FaRoute, FaUser } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { BiPlus } from 'react-icons/bi';
import { CgClose } from 'react-icons/cg';
import Input from '../../components/Input';
import SelectInput from '../../components/SelectInput';

const Home = () => {

  const { user } = useAuth();
  const { reservations, agences, lignes, stations, utilisateurs, voyagesReservations } = useApp();

  const columns = [
    { header: "Agence", accessor: "agence", sortable: true, active: user?.profil === "super-admin" },
    { header: "Date", accessor: "date", sortable: true, active: true },
    { header: "Heure", accessor: "heure_depart", sortable: false, active: true },
    { header: "Reservations", accessor: "reservations", sortable: false, active: true },

    { header: "", accessor: "action", sortable: false, active: true },
  ];

  // Section recherche
  const [inputs, setInputs] = useState({
    query: "",
    agence: "",
  });

  const handleInputChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const [agenceOption, setAgenceOptions] = useState([]);

  useEffect(() => {
    let list = [
      { value: "", label: "Toutes les agences" }
    ];

    agences.map((a, _) => {
      list.push({
        value: a.id,
        label: a.nom
      })
    })

    setAgenceOptions(list);
  }, [agences]);

  const data = groupReservations(reservations)
    .filter(r => r.date && isToday(r.date))
    .filter(r => inputs.agence ? r.agence_id === inputs.agence : true)
    .filter(r => {
      let filter_query = inputs.query?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') || '';
      // Normalisation et suppression des accents de la requête

      // Vérifier si la requête est vide
      if (!filter_query) return true;

      // Fonction pour normaliser et supprimer les accents
      const removeAccents = (str) => str?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') || '';

      // Normaliser les champs à vérifier
      const date = removeAccents(`${formatDateCourte(r.createdAt)}`);
      const agence = removeAccents(`${r.agence_voyage?.nom}`);
      const lignes = removeAccents(`${r.lignes?.map((l, _) => `${l.ville_depart_ligne?.code}_${l.ville_arriee_ligne?.code}${_ < r.lignes.length - 1 ? '  -  ' : ''}`)}`);
      const ville = removeAccents(`${r.lignes?.map((l, _) => `${l.ville_depart_ligne?.nom}_${l.ville_arriee_ligne?.nom}${_ < r.lignes.length - 1 ? '  -  ' : ''}`)}`);
      const stations = removeAccents(`${r.stations?.length}`);
      const jours_voyages = removeAccents(`${(r.jours_voyages?.map((j, _) => {
        return `${j.jour} (${j.heure_depart})${r.jours_voyages?.length - 1 === _ ? '' : '  -  '}`
      }))}`);

      const active = removeAccents(`${r.active ? "Oui" : "Non"}`);

      // Filtrer selon le nom, prénom, téléphone, numéro de billet, source, ou ligne
      return date.includes(filter_query) ||
        agence.includes(filter_query) ||
        lignes.includes(filter_query) ||
        stations.includes(filter_query) ||
        jours_voyages.includes(filter_query) ||
        active.includes(filter_query) ||
        ville.includes(filter_query);
    })
    .map((item, index) => {

      // verifier si la date de voyage est egale a la date d'aujourd'hui
      const is_able_valid = isToday(item.date);

      return ({
        // index: index + 1,
        agence: item?.voyage_info?.agence_voyage?.nom,
        date: formatDateCourte(item.date),
        heure_depart: item?.jour_voyage_info?.heure_depart,
        reservations: item?.reservations?.length,
        action: <div>
          <Link to={`/voyages/details/list/${item.id}`} className='flex justify-center items-center bg-primary text-light p-1 text-lg rounded-lg'><BiPlus /></Link>
        </div>
      })
    })


  const blockStateData = [
    {
      value: `${reservations.filter(r => r.date && isToday(r.date)).length}/${reservations.filter(r => isDateInFuture(r.date)).length}`,
      icon: <FaTicketAlt />,  // Icône de billet pour les réservations
      description: "Réservations du jour",
      path: "/",
      color: "#FF5733",  // Couleur vive pour indiquer les réservations
      active: true,
    },

    {
      value: `${agences.filter(a => a.active).length}/${agences.length}`,
      icon: <FaBuilding />,  // Icône de bâtiment pour les agences
      description: "Agences actives",
      path: "/",
      color: "#FFAA50",  // Couleur chaude pour les agences
      active: user.profil === "super-admin",
    },

    {
      value: `${lignes.filter(l => l.active).length}/${lignes.length}`,
      icon: <FaRoute />,  // Icône de route pour les lignes de voyage
      description: "Lignes de voyage actives",
      path: "/",
      color: "#003300",  // Couleur verte foncée pour les lignes
      active: user.profil !== "super-admin",
    },

    {
      value: `${stations.filter(s => s.active).length}/${stations.length}`,
      icon: <FaBus />,  // Icône de bus pour les stations
      description: "Stations actives",
      path: "/",
      color: "#00B350",  // Couleur verte pour les stations
      active: user.profil !== "super-admin",
    },

    {
      value: `${utilisateurs.filter(u => u.active).length}/${utilisateurs.length}`,
      icon: <FaUser />,  // Icône d'utilisateur pour les utilisateurs actifs
      description: "Utilisateurs actifs",
      path: "/",
      color: "#00B350",  // Couleur similaire pour indiquer l'activité
      active: user.profil === "super-admin" || user.profil === "admin",
    }
  ];


  return (
    <div className='flex flex-col gap-7'>
      <div className='flex md:flex-row flex-col w-full flex-wrap items-start gap-4'>
        {blockStateData.filter(e => e.active).map((b, index) => {
          return (
            <BlockState
              key={index}
              value={b.value}
              icon={b.icon}
              description={b.description}
              path={b.path}
              color={b.color}
            />
          )
        })}
      </div>

      <div>
        {/* Section filtre et recherche */}
        <div className='my-2 bg-white rounded-lg p-2 flex items-end gap-4 flex-col md:flex-row'>
          {user?.profil === "super-admin" && <div className='w-[100%] md:w-[250px] max-w-[100%]'>
            <SelectInput
              label="Filtrer par agence"
              value={inputs.agence}
              onChange={(e) => handleInputChange('agence', e.value)}

              options={agenceOption}
            />
          </div>}

          <div className='w-[100%] flex-1 max-w-[100%]'>
            <Input
              label="Recherche"
              type="text"
              placeholder="Recherche par numero, nom, prenom, etc..."
              value={inputs.query}
              onChange={(e) => handleInputChange('query', e.target.value)}

            />
          </div>

          {(inputs.query || inputs.agence) && <button
            className='text-lg text-red-700 cursor-pointer flex justify-center items-center h-[50px] w-[40px]'
            onClick={() => {
              handleInputChange("query", "");
              handleInputChange("agence", "");
            }}
          >
            <CgClose />
          </button>}
        </div>

        <div>
          <DataTable columns={columns.filter(c => c.active)} data={data} />
        </div>

      </div>
    </div>
  )
}

export default Home