import { createContext, useContext, useState, useEffect } from 'react';
import authService from '../services/authService';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'; // Importer Toast
import 'react-toastify/dist/ReactToastify.css'; // Importer les styles de Toast

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(null);
    const [user, setUser] = useState(null);
    const [onLoading, setOnLoading] = useState(false);
    const [error, setError] = useState(null); // Pour gérer les erreurs

    useEffect(() => {
        (async () => {
            setOnLoading(true);
            setError(null); // Réinitialiser l'erreur avant chaque requête

            try {
                const token_storage = authService.getCurrentToken();

                if (token_storage) {
                    axios.defaults.headers.common['Authorization'] = `Bearer ${token_storage}`;

                    const data = await authService.getCurrentUser();

                    if (data) {
                        setUser(data);
                        setToken(token_storage);
                    }
                }
            } catch (err) {
                setError('Échec du chargement des données utilisateur.'); // Gérer l'erreur
                toast.error('Échec du chargement des données utilisateur'); // Afficher une erreur
            } finally {
                setOnLoading(false);
            }
        })();
    }, []);

    const login = async (email, password) => {
        setOnLoading(true);
        setError(null);

        try {
            const data = await authService.login(email, password);
            setToken(data.token);
            setUser(data.user);

            axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
            toast.success('Connexion réussie'); // Succès de connexion
        } catch (err) {
            setError('Échec de la connexion. Veuillez vérifier vos identifiants.');
            toast.error('Échec de la connexion'); // Afficher une erreur
        } finally {
            setOnLoading(false);
        }
    };

    const logout = () => {
        setOnLoading(true);
        setError(null);

        try {
            setTimeout(() => {
                authService.logout();
                setToken(null);
                setUser(null);

                axios.defaults.headers.common['Authorization'] = ``;
                toast.success('Déconnexion réussie'); // Succès de déconnexion
                setOnLoading(false);
            }, 2000);
        } catch (err) {
            setError('Échec de la déconnexion.'); // Gérer les erreurs de déconnexion
            toast.error('Échec de la déconnexion'); // Afficher une erreur
            setOnLoading(false);
        }
    };

    return (
        <AuthContext.Provider value={{ onLoading, token, user, setUser, login, logout, error }}>
            {children}
            <ToastContainer
                position="bottom-right"
                autoClose={3000}  // Ferme automatiquement après 3 secondes
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}       // Support pour les langues RTL (Right to Left)
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
                theme="colored"   // Thème coloré (par défaut, clair ou foncé)
                limit={3}         // Limite de toasts affichés simultanément
            />

        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
