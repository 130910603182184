import React, { useState } from 'react'

const Textarea = ({
    label,
    placeholder,
    value,
    onChange = () => {},
    onFocus = () => {},
    error,
    required = false,
    ...props
}) => {

    const [focused, setOnFoused] = useState(false);

    return (
        <div className={`flex flex-col gap-1 flex-1`}>
            {label && <label className='text-sm'>{label} {required && <span>*</span>}</label>}

            <div className={`flex items-center border rounded-lg ${(error && !focused) && 'border-red-500'} ${focused && 'border-primary'} min-h-[100px]`}>
                <textarea
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    className="flex-1 px-4 py-2 focus:outline-none rounded-lg min-h-[100px]"
                    // required={required}
                    onFocus={() => {setOnFoused(true); onFocus()}}
                    onBlur={() => setOnFoused(false)}
                    {...props}
                />
            </div>

            {error && <small className='text-[10px] text-red-500'>{error}</small>}
        </div>
    )
}

export default Textarea
