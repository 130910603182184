import React, { useState } from 'react'
import images from '../constants/images'
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'
import { Link, useLocation } from 'react-router-dom';
import { FaMapMarkedAlt, FaRoute, FaSignOutAlt } from 'react-icons/fa';
import { useAuth } from '../contexts/AuthContext';
import { BiBus, BiCalendar, BiSolidCity } from 'react-icons/bi';
import { FaChartBar, FaUsers } from 'react-icons/fa6';
import { TiTicket } from 'react-icons/ti';
import { MdLocationCity } from 'react-icons/md';
import { FiBarChart, FiBarChart2, FiSettings } from 'react-icons/fi';

const SideBar = () => {
  const { logout, user } = useAuth();
  const location = useLocation();
  const device_width = window.screen.width;
  const check_devise = device_width > 992;
  const [showSideBar, setShowSideBar] = useState(false);

  const handleToggleSideBar = () => {
    setShowSideBar(curr => !curr);
  }

  const handleLogout = () => {
    logout();
  }

  const items = [
    {
      name: 'Villes',
      icon: <BiSolidCity />,
      path: '/villes/list',
      pathKey: 'villes',
      role: user?.profil === 'super-admin'
    },

    {
      name: 'Agences',
      icon: <BiBus />,
      path: '/agences/list',
      pathKey: 'agences',
      role: user?.profil === 'super-admin'
    },

    {
      name: 'Stations',
      icon: <MdLocationCity />,
      path: '/stations/list',
      pathKey: 'stations',
      role: true
    },

    {
      name: 'Lignes',
      icon: <FaRoute />,
      path: '/lignes/list',
      pathKey: 'lignes',
      role: true
    },

    {
      name: 'Planning',
      icon: <BiCalendar />,
      path: '/planning/list',
      pathKey: 'planning',
      role: true
    },

    {
      name: 'Voyages',
      icon: <FaMapMarkedAlt />,
      path: '/voyages/list',
      pathKey: 'voyages',
      role: true
    },

    {
      name: 'Reservations',
      icon: <TiTicket />,
      path: '/reservations/list',
      pathKey: 'reservations',
      role: true
    },

    {
      name: 'Utilisateurs',
      icon: <FaUsers />,
      path: '/utilisateurs/list',
      pathKey: 'utilisateurs',
      role: user?.profil !== "caisse"
    },

    {
      name: 'Configurations',
      icon: <FiSettings />,
      path: '/configurations',
      pathKey: 'configurations',
      role: user?.profil === "admin"
    },

    {
      name: 'Reporting',
      icon: <FaChartBar />,
      path: '/reporting',
      pathKey: 'reporting',
      role: true || user?.profil === "admin"
    },
  ]

  return (
    <div className={`absolute max-w-[80%] z-50 bg-white`}>

      {/* Button pour toggle le sidebar */}
      <button onClick={handleToggleSideBar} className='absolute flex justify-center items-center rounded-tr-full rounded-br-full -right-[30px] top-2 bg-primary h-[35px] w-[30px] text-light'>
        {showSideBar ? <BsChevronLeft /> : <BsChevronRight />}
      </button>

      <div className={`h-screen ${check_devise ? 'w-[260px]' : 'w-[300px]'} ${showSideBar ? 'flex' : 'hidden'} flex-col justify-center items-center`}>

        {/* Entete */}
        <Link to={`/`} className='bg-primary flex items-center py-2 px-4 w-full gap-2 h-[56px]'>
          <img src={images.logo} alt='logo FABB' className='w-[30px] h-[30px]' />
          <h1 className='text-md font-mmedium uppercase text-light'>FABB <span className='font-mlight'>Congo</span></h1>
        </Link>

        {/* Afficher le logo et le nom de l'agence, uniquement pour un utilisateur different de superAdmin */}
        {false && <div className='mt-4 w-full'></div>}

        {/* Menu */}
        <div className='w-full flex-1 bg-gray my-4'>
          {items.filter(i => i.role).map((item, index) => {

            return <Link
              key={index}
              to={item.path}
              className={`
                flex items-center gap-2 px-4 py-2 
                hover:text-primary hover:bg-primary/5 hover:border-r-primary hover:border-r-[2.5px]
                focus:text-primary focus:bg-primary/10 focus:border-r-primary focus:border-r-[2.5px]
                ${location.pathname.includes(item.pathKey) && `text-primary bg-primary/10 border-r-primary border-r-[2.5px]`}
              `}
            >
              <span className='text-[16px] w-[30px] h-[30px] rounded-full bg-primary flex justify-center items-center text-light'>
                {item.icon}
              </span>

              <span>{item.name}</span>
            </Link>

          })}
        </div>

        {/* autre lien */}
        <div className='mb-4 w-full flex flex-col justify-center items-center gap-3'>
          <button onClick={handleLogout} className='text-primary flex items-center justify-center border-[0.5px] w-[80%] py-2 px-4 gap-3 rounded-lg border-primary'>
            <FaSignOutAlt />
            <span className='text-sm font-mmedium'>Deconnexion</span>
          </button>
        </div>

      </div>

    </div>
  )
}

export default SideBar