import React, { useEffect, useRef, useState } from 'react'
import DataTable from '../../components/DataTable';
import { useApp } from '../../contexts/AppContext';
import { formatDate, formatDateCourte, formatNumber, groupReservations, isDateLessThanToday, isToday } from '../../constants/functions';
import { useAuth } from '../../contexts/AuthContext';
import EmbarquementButton from '../../components/EmbarquementButton';
import { Link, useParams } from 'react-router-dom';
import { BiEdit, BiPlus, BiPrinter } from 'react-icons/bi';
import SelectInput from '../../components/SelectInput';
import Input from '../../components/Input';
import { CgClose } from 'react-icons/cg';
import colors from '../../constants/colors';
import { BASE_URL } from '../../constants/config';
import images from '../../constants/images';
import { FaBus, FaPlane, FaTrain } from 'react-icons/fa';
import QRCodeDisplay from '../../components/QRCodeDisplay';

const AgencyIcon = ({ typeAgence }) => {
    const iconMap = {
        bus: <FaBus size={15} color={colors.secondary} />,
        avion: <FaPlane size={15} color={colors.secondary} />,
        train: <FaTrain size={15} color={colors.secondary} />,
    };

    return iconMap[typeAgence.toLowerCase()] || null; // Retourne l'icône ou null si le type n'est pas trouvé
};

const ReservationBillet = () => {

    const { user } = useAuth();
    const { reservations, agences } = useApp();
    const { id } = useParams();
    const reservation = reservations.find(r => r.id.toString() === id);

    const hiddenNumber = formatNumber(reservation.id);

    const agence = reservation.ligne_reservation.agence_ligne;

    const ligne = reservation.ligne_reservation;
    const ville_depart = reservation.station_depart_reservation.ville_station;
    const ville_arrivee = reservation.station_arrivee_reservation.ville_station;
    const station_depart = reservation.station_depart_reservation;
    const station_arrivee = reservation.station_arrivee_reservation;
    const jour_voyage = reservation.jour_voyage_reservation;

    const bg_status_paiement = (reservation.status_paiement === "PENDING") ? 'bg-yellow-400' : (reservation.status_paiement === "SUCCESSFUL" && (!reservation.status_embarquement && !isDateLessThanToday(reservation.date))) ? 'bg-green-400' : 'bg-rose-600';
    const agence_logo = agence.logo ? `${BASE_URL}/${agence.logo}` : images.agence_test;

    const printRef = useRef();

    const handlePrint = () => {

        // Récupérer les styles de la page (styles globaux)
        const styles = Array.from(document.styleSheets)
            .map(sheet => {
                try {
                    return Array.from(sheet.cssRules).map(rule => rule.cssText).join(' ');
                } catch (e) {
                    // Ignorer les erreurs liées aux feuilles de styles externes (CORS)
                    return '';
                }
            })
            .join(' ');

        // Créer le contenu à imprimer
        const content = `
          <html>
            <head>
              <title>Impression</title>
              <style>
                ${styles} /* Ajoute les styles globaux */
                @media print {
                  body {
                    font-family: Arial, sans-serif;
                  }
                  /* Ajoutez d'autres styles spécifiques à l'impression ici */
                }
              </style>
            </head>
            <body>
              ${printRef.current.innerHTML}  <!-- Contenu à imprimer -->
            </body>
          </html>
        `;

        // Ouvrir la fenêtre d'impression mais masquer l'interface
        const printWindow = window.open('', '', 'height=500,width=800,location=no,menubar=no,status=no,scrollbars=no');
        printWindow.document.write(content);
        printWindow.document.close();  // Nécessaire pour Firefox

        // Lance l'impression (sans interaction utilisateur)
        setTimeout(() => {
            printWindow.print();
            printWindow.close();
        }, 500);
    };

    return (
        <div className='flex flex-col gap-4'>
            {/* Entete */}
            <div className='bg-white px-2 py-1 rounded-l'>
                <div className='flex flex-col md:flex-row md:justify-between md:items-center gap-4'>
                    <div>
                        <h2 className='font-mmedium text-lg'>Billet N°{formatNumber(id)}</h2>
                        <p className='text-md'>Status de billet : <span className={`font-mbold`}></span></p>
                    </div>

                    {(
                        // user?.profil === 'admin' || 
                        user?.profil === 'caisse') && <div className='flex'>
                            <button onClick={handlePrint} className='flex items-center gap-2 bg-primary text-light p-2 rounded-lg'>
                                <BiPrinter />
                                <span className='text-sm font-mlight'>Imprimer</span>
                            </button>
                        </div>}
                </div>

            </div>

            <div ref={printRef} className='w-[100%] md:w-[350px] max-w-[100%] self-center relative'>
                <div className="bg-light p-2 w-full" style={{ borderBottomWidth: 5, borderBottomColor: colors.primary }}>
                    <div className="z-10">
                        <div className="flex flex-row items-center justify-between px-2" style={{ borderBottomWidth: 1, borderBottomColor: colors.gray }}>
                            <div className="flex flex-row items-center">
                                <div className="w-[120px] max-w-[30%] h-[80px] bg-light p-2">
                                    <img
                                        src={agence_logo}
                                        className="w-[100%] h-[100%] rounded-lg"
                                        style={{ objectFit: 'contain' }}
                                        alt='logo agence'
                                    />

                                </div>

                                <div className="p-4">
                                    <p className="font-mmedium text-lg">{agence.nom}</p>

                                    <div className="flex flex-row items-center gap-1">
                                        <AgencyIcon typeAgence={agence.type_agence} />
                                        <p className="text-md text-secondary font-mregular">{agence.type_agence}</p>
                                    </div>
                                </div>
                            </div>

                            <QRCodeDisplay size={100} qrValue={`Billet N°${hiddenNumber}\nNom(s) : ${reservation.nom} \nPrénom(s) : ${reservation.prenom} \nTéléphone: ${reservation.telephone} \nAgence: ${agence.nom} \nDépart : (${ville_depart.code}) ${station_depart.designation} \nArrivée : (${ville_arrivee.code}) ${station_arrivee.designation} \nDate et heure de Départ : Le ${formatDate(reservation.date)} à ${jour_voyage.heure_depart}`} />
                        </div>

                        <div className="my-4" style={{ borderWidth: 1, borderColor: colors.gray }}>
                            <div className="flex bg-[gray] justify-center items-center py-2">
                                <p className="text-light font-mbold text-md">Billet N°{hiddenNumber}</p>
                            </div>

                            <div className="flex flex-row justify-between">
                                <div className="w-[50%] min-h-[50px] p-2 justify-center" style={{ borderWidth: 0.5, borderColor: colors.gray }}>
                                    <p className="text-md font-mregular">Nom(s) :</p>
                                </div>
                                <div className="w-[50%] min-h-[50px] p-2 justify-center" style={{ borderWidth: 0.5, borderColor: colors.gray }}>
                                    <p className="text-md font-mmedium text-primary">{reservation.nom}</p>
                                </div>
                            </div>

                            <div className="flex flex-row justify-between">
                                <div className="w-[50%] min-h-[50px] p-2 justify-center" style={{ borderWidth: 0.5, borderColor: colors.gray }}>
                                    <p className="text-md font-mregular">Prénom(s) :</p>
                                </div>
                                <div className="w-[50%] min-h-[50px] p-2 justify-center" style={{ borderWidth: 0.5, borderColor: colors.gray }}>
                                    <p className="text-md font-mmedium text-primary">{reservation.prenom}</p>
                                </div>
                            </div>

                            <div className="flex flex-row justify-between">
                                <div className="w-[50%] min-h-[50px] p-2 justify-center" style={{ borderWidth: 0.5, borderColor: colors.gray }}>
                                    <p className="text-md font-mregular">Téléphone :</p>
                                </div>
                                <div className="w-[50%] min-h-[50px] p-2 justify-center" style={{ borderWidth: 0.5, borderColor: colors.gray }}>
                                    <p className="text-md font-mregular">{reservation.telephone}</p>
                                </div>
                            </div>

                            <div className="flex flex-row justify-between">
                                <div className="w-[50%] min-h-[50px] p-2 justify-center" style={{ borderWidth: 0.5, borderColor: colors.gray }}>
                                    <p className="text-md font-mregular">Date et heure de Départ :</p>
                                </div>
                                <div className="w-[50%] min-h-[50px] p-2 justify-center" style={{ borderWidth: 0.5, borderColor: colors.gray }}>
                                    <p className="text-md font-mmedium text-primary">Le {formatDate(reservation.date)} à {jour_voyage.heure_depart}</p>
                                </div>
                            </div>

                            <div className="flex flex-row justify-between">
                                <div className="w-[50%] min-h-[50px] p-2 justify-center" style={{ borderWidth: 0.5, borderColor: colors.gray }}>
                                    <p className="text-md font-mregular">Départ :</p>
                                </div>
                                <div className="w-[50%] min-h-[50px] p-2 justify-center" style={{ borderWidth: 0.5, borderColor: colors.gray }}>
                                    <p className="text-md font-mregular">({ville_depart.code}) {station_depart.designation}</p>
                                </div>
                            </div>

                            <div className="flex flex-row justify-between">
                                <div className="w-[50%] min-h-[50px] p-2 justify-center" style={{ borderWidth: 0.5, borderColor: colors.gray }}>
                                    <p className="text-md font-mregular">Arriée :</p>
                                </div>
                                <div className="w-[50%] min-h-[50px] p-2 justify-center" style={{ borderWidth: 0.5, borderColor: colors.gray }}>
                                    <p className="text-md font-mregular">({ville_arrivee.code}) {station_arrivee.designation}</p>
                                </div>
                            </div>

                            <div className="flex flex-row justify-between">
                                <div className="w-[50%] min-h-[50px] p-2 justify-center" style={{ borderWidth: 0.5, borderColor: colors.gray }}>
                                    <p className="text-md font-mregular">Type de billet :</p>
                                </div>
                                <div className="w-[50%] min-h-[50px] p-2 justify-center" style={{ borderWidth: 0.5, borderColor: colors.gray }}>
                                    <p className="text-md font-mregular">{reservation.type_reservation.replace(/_/g, ' ').toUpperCase()}</p>
                                </div>
                            </div>

                            <div className="flex flex-row justify-between">
                                <div className="w-[50%] min-h-[50px] p-2 justify-center" style={{ borderWidth: 0.5, borderColor: colors.gray }}>
                                    <p className="text-md font-mregular">Nombre de place :</p>
                                </div>
                                <div className="w-[50%] min-h-[50px] p-2 justify-center" style={{ borderWidth: 0.5, borderColor: colors.gray }}>
                                    <p className="text-md font-mregular">{reservation.nombre_place_adulte}</p>
                                </div>
                            </div>

                            <div className="flex flex-row justify-between">
                                <div className="w-[50%] min-h-[50px] p-2 justify-center" style={{ borderWidth: 0.5, borderColor: colors.gray }}>
                                    <p className="text-lg font-mregular">Montant payer</p>
                                </div>
                                <div className="w-[50%] min-h-[50px] p-2 justify-center" style={{ borderWidth: 0.5, borderColor: colors.gray }}>
                                    <p className="text-lg font-mmedium">{reservation.montant_a_payer_total} FCFA</p>
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-center items-center pt-3" style={{ borderTopWidth: 2, borderTopColor: colors.gray, borderStyle: 'dashed' }}>
                            {/* <BarcodeComponent value={hiddenNumber} /> */}
                        </div>

                        <div>
                            <p className="text-center text-md font-mlight">{agence.texte_billet}</p>
                        </div>
                    </div>

                    <div className="w-full h-full top-0 absolute z-0 flex justify-center items-center" style={{ opacity: 0.1 }}>
                        <img
                            src={images.logo}
                            resizeMode='contain'
                            className="w-[80%] h-200px]"
                        />
                    </div>

                    {reservation.status_embarquement && <div className="w-full h-full absolute z-20 flex justify-center items-center" style={{ opacity: 1 }}>
                        <p style={{ transform: [{ rotate: '-30deg' }] }} className="text-red text-4xl text-center font-mbold">EMBARQUEMENT CONFIRMÉ</p>
                    </div>}

                    {(!reservation.status_embarquement && isDateLessThanToday(reservation.date)) && <div className="w-full h-full absolute z-20 flex justify-center items-center" style={{ opacity: 1 }}>
                        <p style={{ transform: [{ rotate: '-30deg' }] }} className="text-6xl text-yellow-500 text-center font-mbold">INVALIDE</p>
                    </div>}

                </div>
            </div>
        </div>
    )
}

export default ReservationBillet
