import React from 'react'
import SideBar from './SideBar'
import Header from './Header'
import { Outlet } from 'react-router-dom'

const Layouts = () => {
    return (
        <div>
            <div className='flex'>
                <SideBar/>
                <div className='w-full'>
                    <Header/>
                    <div className='p-4 max-h-[90vh]' style={{overflowY: 'scroll'}}>
                        <Outlet/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Layouts
