export const type_agence_options = [
    {value: "BUS", label: "Bus"},
    {value: "AVION", label: "Avion"},
    {value: "TRAIN", label: "Train"},
]

export const active_options = [
    {value: true, label: "Oui"},
    {value: false, label: "Non"},
]

export const jours_options =[
    { value: 'Lundi', label: 'Lundi' },
    { value: 'Mardi', label: 'Mardi' },
    { value: 'Mercredi', label: 'Mercredi' },
    { value: 'Jeudi', label: 'Jeudi' },
    { value: 'Vendredi', label: 'Vendredi' },
    { value: 'Samedi', label: 'Samedi' },
    { value: 'Dimanche', label: 'Dimanche' }
  ]
  