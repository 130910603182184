import React, { useEffect, useState } from 'react'
import Input from '../../components/Input';
import SelectInput from '../../components/SelectInput';
import { active_options, type_agence_options } from '../../constants/values';
import { useNavigate, useParams } from 'react-router-dom';
import { useApp } from '../../contexts/AppContext';
import { useAuth } from '../../contexts/AuthContext';

const VilleEdit = () => {

    const { user } = useAuth();
    const { villes, update_ville, pays } = useApp();

    const { id } = useParams();

    const ville = villes.find(a => a.id.toString() === id);

    const defaultElement = {
        pays_id: "",
        nom: "",
        code: "",
        active: true
    };

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [inputs, setInputs] = useState(ville || defaultElement);

    const [errors, setErrors] = useState({});

    const handleInputChange = (name, value) => {
        setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
    };

    const handleInputChangeError = (name, value) => {
        setErrors((prevInputs) => ({ ...prevInputs, [name]: value }));
    };

    const validate = () => {
        let valid = true;
        let errors = {};
    
        // Vérification du nom
        if (!inputs.nom) {
            errors.nom = 'Le nom est obligatoire';
            valid = false;
        } 
        else if (/[^a-zA-Z0-9\s]/.test(inputs.nom)) {
            errors.nom = 'Le nom ne doit pas contenir de caractères spéciaux';
            valid = false;
        }
        else if (villes.some(ville => ville.nom.toLowerCase() === inputs.nom.toLowerCase() && ville.id !== inputs.id)) {
            errors.nom = 'Une autre ville avec ce nom existe déjà';
            valid = false;
        }
    
        // Vérification du pays
        if (!inputs.pays_id) {
            errors.pays_id = 'Le pays est obligatoire';
            valid = false;
        }
    
        // Vérification du code
        if (!inputs.code) {
            errors.code = 'Le code/abréviation de la ville est obligatoire';
            valid = false;
        } 
        else if (/[^a-zA-Z0-9\s]/.test(inputs.code)) {
            errors.code = 'Le code ne doit pas contenir de caractères spéciaux';
            valid = false;
        }
        else if (villes.some(ville => ville.code.toLowerCase() === inputs.code.toLowerCase() && ville.id !== inputs.id)) {
            errors.code = 'Une autre ville avec ce code existe déjà';
            valid = false;
        }
    
        setErrors(errors);
        return valid;
    };
    

    const handleSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        try {
            if (validate()) {
                await update_ville(inputs);

                navigate(-1);
            }
            setIsLoading(false);
        } catch (error) {
            console.log("Failed to login", error);
            setIsLoading(false);
        }
    };

    const handleReset = () => {
        navigate(-1);
    }

    const [paysOptions, setPaysOptions] = useState([]);

    useEffect(() => {

        let list = [];

        pays.map((p, _) => {
            list.push({
                value: p.id,
                label: p.nom
            })
        });

        setPaysOptions(list);
    }, [pays])

    console.log("Ville update : ", ville)


    return (
        <div className='flex flex-col w-full items-center'>
            <form onSubmit={handleSubmit} encType="multipart/form-data" className="space-y-4 w-[100%] md:min-w-[700px] bg-white p-2 md:p-4 rounded-lg">

                <div className='border-b pb-4 mb-5'>
                    <h1 className='font-mmedium text-lg'>Enregistrer une ville</h1>
                    <p className='text-md'>Enregistrer une nouvelle ville en remplissant le formulaire ci-dessus</p>
                </div>

                <div className='space-y-4'>

                    <div className='flex gap-4 md:gap-2 flex-col md:flex-row'>
                        <Input
                            label="Nom"
                            type="text"
                            placeholder="Nom de la ville"
                            value={inputs.nom}
                            error={errors.nom}
                            onChange={(e) => handleInputChange('nom', e.target.value)}
                            onFocus={() => handleInputChangeError('nom', null)}

                        />

                        <Input
                            label="Code"
                            type="text"
                            placeholder="Code/Abrevation du nom de la ville"
                            value={inputs.code}
                            error={errors.code}
                            onChange={(e) => handleInputChange('code', e.target.value)}
                            onFocus={() => handleInputChangeError('code', null)}

                        />
                    </div>

                    <div className='flex gap-4 md:gap-2 flex-col md:flex-row'>
                        <SelectInput
                            label="Pays"
                            value={inputs.pays_id}
                            onChange={(e) => handleInputChange('pays_id', e.value)}
                            onFocus={() => handleInputChangeError('pays_id', null)}

                            options={paysOptions}
                            error={errors.pays_id}
                            required
                        />


                        <SelectInput
                            label="Active"
                            value={inputs.active}
                            onChange={(e) => handleInputChange('active', e.value)}
                            onFocus={() => handleInputChangeError('active', null)}

                            options={active_options}
                            error={errors.active}
                            required
                        />
                    </div>

                </div>

                <div className='flex justify-end mt-5 gap-2'>
                    <button onClick={handleReset} className='flex-1 md:flex-none border border-primary rounded-lg px-4 py-1 text-primary text-md'>Annuler</button>
                    <button type='submit' className='flex-1 md:flex-none border border-primary bg-primary rounded-lg px-4 py-1 text-light text-md' disabled={isLoading}>{!isLoading ? 'Enregister' : 'Loading ...'}</button>
                </div>
            </form>
        </div>
    )
}

export default VilleEdit

