import React from 'react';
import Select from 'react-select';
import colors from '../constants/colors';

const SelectInput = ({
    label,
    value,
    error,
    required,
    placeholder,
    options = [],
    isMulti = false,
    onChange = () => { },
    onFocus = () => { }
}) => {
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#ffffff',  // Couleur d'arrière-plan de la zone de sélection
            borderColor: state.isFocused ? colors.primary : error ? colors.primary : '#ddd',  // Changer la couleur de la bordure selon l'état
            boxShadow: state.isFocused ? `0 0 0 1px ${colors.primary}` : 'none',
            height: 45,
            '&:hover': {
                borderColor: colors.primary
            }
        }),

        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? colors.primary : state.isFocused ? colors.primary_rgba : 'white',
            color: state.isSelected ? 'white' : 'black',
            padding: 10,
        }),

        singleValue: (provided, state) => ({
            ...provided,
            color: '#333',  // Couleur du texte sélectionné
        }),

        placeholder: (provided) => ({
            ...provided,
            color: '#888',  // Couleur du placeholder
        }),

        menu: (provided) => ({
            ...provided,
            borderRadius: '4px',
            marginTop: '4px',
        }),

        menuList: (provided) => ({
            ...provided,
            padding: 0
        }),
    };

    console.log(value)

    return <div className={`flex flex-col gap-1 flex-1`}>
        {label && <label className='text-sm'>{label} {required && <span>*</span>}</label>}

        <Select
            styles={customStyles}
            defaultValue={
                value !== ''
                    ? Array.isArray(value)
                        ? value
                        : options.find(v => v.value === value)
                    : null
            }
            value={
                value !== ''
                    ? Array.isArray(value)
                        ? value
                        : options.find(v => v.value === value)
                    : null
            }
            onChange={onChange}
            options={options}
            placeholder={placeholder}
            noOptionsMessage={() => "Aucun résultat"}
            onFocus={onFocus}  // Simplification de onFocus
            isMulti={isMulti}
        />


        {error && <small className='text-[10px] text-red-500'>{error}</small>}
    </div>
};

export default SelectInput;
