import React from 'react'
import images from '../constants/images'

const Button = ({
    text,
    loading=false,
}) => {
    return (
        <button
            disabled={loading}
            type="submit"
            className="flex justify-center items-center w-full px-4 py-2 text-white bg-primary rounded-lg hover:bg-primary/80 h-[40px]"
        >
            {!loading ? text : <img src={images.spinner_light} alt='spinner-light' className="object-contain w-[50px] h-[35px]"/>}
        </button>
    )
}

export default Button
