import React from 'react'
import images from '../constants/images'

const OnLoading = () => {
  return (
    <div className='flex items-center justify-center h-screen p-4'>
        <img src={images.spinner_primary} alt='spinner primary' className='object-contain w-[250px] max-w-[80%]'/>
    </div>
  )
}

export default OnLoading