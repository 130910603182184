import { format } from 'date-fns';
import { fr } from 'date-fns/locale';


const getElementData = (id, element) => {
  let response = {};

  try {
    response = element.find(item => item.id.toString() === id.toString());

    return response;

  } catch (error) {
    return response;
  }
};

const validatePhoneNumber = (regex_value, phoneNumber) => {
  let phoneRegex = regex_value;
  let regex = phoneRegex ? new RegExp(phoneRegex) : null;

  if (!regex) return true;

  return regex.test(phoneNumber);
};

const formaterDate = (date) => {
  let now = new Date(date)
  // return format(now, 'EEE dd MMM yyyy', { locale: fr });
  return format(now, 'dd/MM/yyyy', { locale: fr });
}

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  const formattedDate = date.toLocaleDateString('fr-FR', options).replace(/ /g, ' ');

  return formattedDate; // Exemple de résultat : 20-Apr-2023
}

const formatDateCourte = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Les mois sont 0-indexés, donc ajoutez 1
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

const formatDateTime = (dateString) => {
  const date = new Date(dateString);
  const options = { day: '2-digit', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
  const formattedDate = date.toLocaleDateString('fr-FR', options).replace(/ /g, ' ');

  return formattedDate; // Exemple de résultat : 20-Apr-2023
}

const formatPrice = (arg) => {
  //const value = new Intl.NumberFormat('fr-FR').format(arg);
  return String(arg).replace(/(.)(?=(\d{3})+$)/g, '$1 ');
}

const calculateMaxDate = (age) => {
  const today = new Date();
  const maxDate = new Date(today.getFullYear() - parseInt(age), today.getMonth(), today.getDate());
  return maxDate.toISOString().split('T')[0]; // Formate la date en "YYYY-MM-DD"
};

const generateStringFromRegex = (regex_params) => {
  // const regex = /^(06|07|05|04)\d{7}$/; // Regex spécifié
  // let randomString = '';

  // // Génération d'une chaîne de caractères aléatoire jusqu'à ce qu'elle corresponde au regex
  // while (!regex.test(randomString)) {
  //   // Génération d'une chaîne aléatoire de longueur 7 composée de chiffres
  //   randomString = Math.floor(Math.random() * 10000000).toString().padStart(7, '0');
  //   // Ajout d'un préfixe aléatoire pour correspondre au regex
  //   randomString = ['06', '07', '05', '04'][Math.floor(Math.random() * 4)] + randomString;
  // }

  return "Numéro de téléphone";
}

const validateEmail = (email) => {
  // Expression régulière pour vérifier une adresse email
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

const getLabelByValueOption = (dataSelect = [], value) => {
  const ville = dataSelect.find(option => option.value === value);
  return ville ? ville.label : "Label non trouvé";
};

const validatePassword = (password) => {
  // Minimum 8 caractères, au moins une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  if (passwordRegex.test(password)) {
    return { valid: true, message: "Le mot de passe est fort." };
  } else {
    let errorMessage = "Le mot de passe doit contenir au moins 8 caractères, ";
    errorMessage += "une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial.";

    return { valid: false, message: errorMessage };
  }
};

function formatNumber(number) {
  const numberString = number.toString();
  return `FABB${numberString.padStart(7, '0')}`;
}

function isDateLessThanToday(dateToCheck, daysToAdd = 0) {
  const today = new Date();
  today.setHours(12, 0, 0, 0); // Fixer l'heure à midi pour éviter les problèmes de comparaison d'heures

  const dateToCheckObj = new Date(dateToCheck);
  dateToCheckObj.setHours(12, 0, 0, 0); // Fixer l'heure à midi pour une comparaison basée uniquement sur la date

  const futureDate = new Date(dateToCheck);
  futureDate.setDate(dateToCheckObj.getDate() + daysToAdd);
  futureDate.setHours(12, 0, 0, 0); // Fixer l'heure à midi pour une comparaison cohérente

  return futureDate < today; // Retourner true si la date est inférieure à aujourd'hui, sinon false
}

const getDayLabel = (date) => {
  // Vérifie si le paramètre est une instance de Date
  if (!(date instanceof Date)) {
    return null;
  }

  // Tableau des labels des jours de la semaine
  const dayLabels = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];

  // Récupère l'index du jour de la semaine
  const dayIndex = date.getDay();

  // Renvoie le label du jour
  return dayLabels[dayIndex];
};

const isToday = (date) => {
  const today = new Date();
  const date_check = new Date(date);

  const trying =
    today.getFullYear() === date_check.getFullYear() &&
    today.getMonth() === date_check.getMonth() &&
    today.getDate() === date_check.getDate();

  return trying
}

function isDateInFuture(date) {
  const currentDate = new Date();
  return new Date(date) > currentDate;
}


const groupReservations = (reservations) => {
  // Utilisation de reduce pour grouper les réservations
  const grouped = reservations.reduce((acc, reservation) => {
    const { voyage_id, jour_voyage_id, date, ligne_id, agence_id, ligne_reservation } = reservation;

    // Extraire uniquement la partie date (sans l'heure)
    const datePart = new Date(date).toISOString().split('T')[0];  // Format YYYY-MM-DD

    // Clé unique pour le regroupement : voyage, jour_voyage et date (sans l'heure)
    const groupKey = `${voyage_id}-${jour_voyage_id}-${datePart}`;

    if (!acc[groupKey]) {
      // Si le groupe n'existe pas, on le crée
      acc[groupKey] = {
        id: `${jour_voyage_id}-${voyage_id}-${datePart}`,
        voyage_id: voyage_id,
        agence_id: agence_id,
        agence: ligne_reservation?.agence_ligne,
        jour_voyage_id: jour_voyage_id,
        date: datePart,  // Utiliser uniquement la date
        reservations: [],
        voyage_info: reservation.reservation_voyage,
        jour_voyage_info: reservation.jour_voyage_reservation,
        reservations_by_ligne: []  // On initialise la liste pour stocker les objets {ligne_id, reservations}
      };
    }

    // Ajout de la réservation au groupe correspondant
    acc[groupKey].reservations.push(reservation);

    // Trouver si la ligne existe déjà dans la liste
    const ligneIndex = acc[groupKey].reservations_by_ligne.findIndex(l => l.ligne_id === ligne_id);
    
    if (ligneIndex === -1) {
      // Si la ligne n'existe pas encore, on l'ajoute avec un compteur initial à 1
      acc[groupKey].reservations_by_ligne.push({
        ligne_id: ligne_id,
        reservations: 1
      });
    } else {
      // Sinon, on incrémente simplement le compteur pour cette ligne
      acc[groupKey].reservations_by_ligne[ligneIndex].reservations += 1;
    }

    return acc;
  }, {});

  // Retourner les groupes sous forme de tableau
  return Object.values(grouped);
};


function abbreviateWords(input) {
  const words = input.trim().split(/\s+/); // Sépare la chaîne en mots

  if (words.length === 1) {
      // Si un seul mot, renvoyer les deux premières lettres
      return words[0].substring(0, 2);
  } else if (words.length === 2) {
      // Si deux mots, renvoyer la première lettre de chaque mot
      return words[0][0] + words[1][0];
  } else {
      // Si plus de deux mots, renvoyer la première lettre des deux premiers mots
      return words[0][0] + words[1][0];
  }
}


export {
  getElementData,
  validatePhoneNumber,
  formaterDate,
  formatDate,
  formatDateTime,
  formatPrice,
  calculateMaxDate,
  formatDateCourte,
  generateStringFromRegex,
  validateEmail,
  getLabelByValueOption,
  validatePassword,
  formatNumber,
  isDateLessThanToday,

  getDayLabel,
  isToday,
  isDateInFuture,

  groupReservations,

  abbreviateWords
};