import React, { useState } from 'react'
import { useApp } from '../contexts/AppContext';
import images from '../constants/images';

const EmbarquementButton = ({data}) => {

    const { embarquement_reservation, reservations } = useApp();
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(data?.status_embarquement);

    const handleEmbarquement = async () => {
        setLoading(true);
        const response = await embarquement_reservation({reservation_id: data.id});
        
        if(response?.success){
          setStatus(response?.value);
        }

        setLoading(false);
    }

    const text = status ? "Annuler" : "valider"

  return (
    !loading ? <button onClick={handleEmbarquement} className={`flex justify-center items-center ${status ? 'bg-red-700' : 'bg-primary'} text-light py-1 px-2 text-sm rounded-lg`}>
             {text} 
    </button> :
    <img src={images.spinner_primary} alt='spinner-light' className="object-contain w-[50px] h-[40px]"/>
  )
}

export default EmbarquementButton