import axios from 'axios';
import { BASE_URL } from '../constants/config';
import api from './api';

const API_URL = `${BASE_URL}`;

const login = async (email, password) => {
    try {
        const response = await api.post(`/utilisateurs/login`, { email, password });
        if (response.data.token) {
            localStorage.setItem('token', response.data.token);
        }
        return response.data;
    } catch (error) {
        console.error('Login failed:', error);
        throw new Error('Failed to login. Please check your credentials.');
    }
};

const register = async (email, password) => {
    try {
        const response = await axios.post(`${API_URL}/register`, { email, password });
        return response.data;
    } catch (error) {
        console.error('Registration failed:', error);
        throw new Error('Failed to register. Please try again.');
    }
};

const logout = () => {
    try {
        localStorage.removeItem('token');
    } catch (error) {
        console.error('Logout failed:', error);
        throw new Error('Failed to logout. Please try again.');
    }
};

const getCurrentUser = async () => {
    try {
        const response = await api.get(`/utilisateurs`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch current user:', error);
        throw new Error('Failed to fetch current user.');
    }
};

const getCurrentToken = () => {
    try {
        return localStorage.getItem('token');
    } catch (error) {
        console.error('Failed to retrieve token:', error);
        return null;
    }
};

const authService = {
    login,
    register,
    logout,
    getCurrentUser,
    getCurrentToken
};

export default authService;
