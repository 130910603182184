import React, { useState } from "react";
import { BiSort, BiSortDown, BiSortUp } from "react-icons/bi";
import { CSVLink } from 'react-csv'; // Pour l'export CSV
import * as XLSX from 'xlsx'; // Pour l'export Excel
import jsPDF from 'jspdf'; // Pour l'export PDF
import 'jspdf-autotable'; // Pour l'export PDF avec tables
import { AiOutlineFileExcel, AiOutlineFilePdf, AiOutlineFile } from 'react-icons/ai'; // Icônes

const DataTable = ({ columns, data }) => {
  // État pour garder la colonne triée et l'ordre de tri
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  // Fonction de tri
  const handleSort = (accessor) => {
    const column = columns.find(col => col.accessor === accessor);
    if (!column.sortable) return; // Ignore si la colonne n'est pas triable

    let direction = 'asc';
    if (sortConfig.key === accessor && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key: accessor, direction });
  };

  // Fonction pour trier les données
  const sortedData = React.useMemo(() => {
    if (sortConfig.key) {
      return [...data].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return data;
  }, [data, sortConfig]);

  // Fonction pour nettoyer les données (extraction du texte des éléments JSX)
  const cleanDataForExport = (data, columns) => {
    return data.map(row => {
      const cleanedRow = {};
      columns.forEach(col => {
        let value = row[col.accessor];

        // Si la valeur est un élément React, récupère son texte, sinon laisse tel quel
        if (React.isValidElement(value)) {
          cleanedRow[col.accessor] = value.props.children || '';
        } else {
          cleanedRow[col.accessor] = value;
        }
      });
      return cleanedRow;
    });
  };

  // Fonction pour exporter en Excel
  const exportToExcel = (cleanedData) => {
    const ws = XLSX.utils.json_to_sheet(cleanedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "data.xlsx");
  };

  // Fonction pour exporter en PDF
  const exportToPDF = (cleanedData) => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [columns.map(col => col.header)],
      body: cleanedData.map(row => columns.map(col => row[col.accessor])),
    });
    doc.save("data.pdf");
  };

  return (
    <div className="relative overflow-x-auto bg-white">
      {/* Boutons d'export */}
      <div className="flex mb-4 justify-end m-2">

        {/* Export Excel */}
        <button 
          onClick={() => exportToExcel(cleanDataForExport(sortedData, columns))} 
          className="px-4 py-2 bg-green-500 text-white rounded flex items-center mr-2"
        >
          <AiOutlineFileExcel /> {/* Icône pour Excel */}
        </button>

        {/* Export PDF */}
        <button 
          onClick={() => exportToPDF(cleanDataForExport(sortedData, columns))} 
          className="px-4 py-2 bg-red-500 text-white rounded flex items-center"
        >
          <AiOutlineFilePdf /> {/* Icône pour PDF */}
        </button>
      </div>

      {/* Tableau des données */}
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            {columns.map((column, index) => (
              <th
                key={index}
                scope="col"
                className={`px-6 py-3 ${column.sortable ? 'cursor-pointer' : ''}`} // Ajoute le curseur si triable
                onClick={() => column.sortable && handleSort(column.accessor)} // Tri seulement si c'est sortable
              >
                <div className="flex items-center">
                  {column.header}
                  {column.sortable && (
                    <>
                      {sortConfig.key === column.accessor ? (
                        sortConfig.direction === 'asc' ? (
                          <BiSortUp />
                        ) : (
                          <BiSortDown />
                        )
                      ) : (
                        <BiSort />
                      )}
                    </>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedData.map((row, rowIndex) => (
            <tr key={rowIndex} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              {columns.map((column, colIndex) => (
                <td key={colIndex} className="px-6 py-4">
                  {typeof row[column.accessor] === "string" ? row[column.accessor] : row[column.accessor]}
                </td>
              ))}
            </tr>
          ))}

          {sortedData.length === 0 && (
            <tr>
              <td colSpan={columns.length} className="h-[100px] text-center bg-white">Aucune ligne</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
