import React, { useEffect, useState } from 'react'
import DataTable from '../../components/DataTable';
import { useApp } from '../../contexts/AppContext';
import { formatDateCourte, formatNumber } from '../../constants/functions';
import { useAuth } from '../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import { BiEdit, BiPlus } from 'react-icons/bi';
import Input from '../../components/Input';
import { CgClose } from 'react-icons/cg';
import { BsTicket } from 'react-icons/bs';

const VillesList = () => {

    const { user } = useAuth();
    const { villes, agences } = useApp();

    const columns = [
        { header: "N°", accessor: "index", sortable: true, active: true },
        // { header: "", accessor: "logo", sortable: false },
        { header: "Nom", accessor: "nom", sortable: true, active: true },
        { header: "Code", accessor: "code", sortable: true, active: true },
        { header: "Pays", accessor: "pays", sortable: true, active: true },
        { header: "Active", accessor: "active", sortable: false, active: true },
        { header: "Création", accessor: "createdAt", sortable: true, active: true },
        { header: "", accessor: "action", sortable: false, active: user?.profil === "super-admin" },
    ];

    // Section recherche
    const [inputs, setInputs] = useState({
        query: "",
        agence: "",
        date_debut: "",
        date_fin: "",
        date_filter: "date_depart"
    });

    const handleInputChange = (name, value) => {
        setInputs((prev) => ({ ...prev, [name]: value }));
    };


    const data = villes
        .filter(r => {
            let filter_query = inputs.query?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') || '';
            // Normalisation et suppression des accents de la requête

            // Vérifier si la requête est vide
            if (!filter_query) return true;

            // Fonction pour normaliser et supprimer les accents
            const removeAccents = (str) => str?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') || '';

            // Normaliser les champs à vérifier
            const nom = removeAccents(r.nom);
            const code = removeAccents(r.code);
            const pays = removeAccents(formatNumber(r.id));

            // Filtrer selon le nom, prénom, téléphone, numéro de billet, source, ou ligne
            return nom.includes(filter_query) ||
                code.includes(filter_query) ||
                pays.includes(filter_query);
        })
        .map((item, index) => {

            return ({
                index: index + 1,
                nom: item?.nom,
                code: item.code,
                pays: item.nom,
                active: item.active ? "Oui" : "Non",
                createdAt: formatDateCourte(item.createdAt),
                action: <div className='flex-row items-center gap-4'>
                    <Link to={`/villes/edit/${item.id}`} className='inline-flex justify-center items-center bg-primary text-light p-2 text-lg rounded-lg ml-2'><BiEdit /></Link>
                </div>
            })
        })


        console.log(villes)
    return (
        <div className='flex flex-col gap-4'>
            {/* Entete */}
            <div className='bg-white px-2 py-1 rounded-l'>
                <div className='flex flex-col md:flex-row md:justify-between md:items-center gap-4'>
                    <div>
                        <h2 className='font-mmedium text-lg'>Villes</h2>
                        <p className='text-md'>Liste des villes</p>
                    </div>

                    {(
                        // user?.profil === 'admin' || 
                        user?.profil === 'super-admin') && <div className='flex'>
                            <Link to={`/villes/add`} className='flex items-center gap-2 bg-primary text-light p-2 rounded-lg'>
                                <BiPlus />
                                <span className='text-sm font-mlight'>Enregistrer</span>
                            </Link>
                        </div>}
                </div>

            </div>

            {/* Section filtre et recherche */}
            <div className='my-2 bg-white rounded-lg p-2 flex items-end gap-4 flex-col md:flex-row'>

                <div className='w-[100%] flex-1 max-w-[100%]'>
                    <Input
                        label="Recherche"
                        type="text"
                        placeholder="Recherche par numero, nom, prenom, etc..."
                        value={inputs.query}
                        onChange={(e) => handleInputChange('query', e.target.value)}

                    />
                </div>

                {(inputs.query || inputs.agence || inputs.date_debut || inputs.date_fin) && <button
                    className='text-lg text-red-700 cursor-pointer flex justify-center items-center h-[50px] w-[40px]'
                    onClick={() => {
                        handleInputChange("query", "");
                        handleInputChange("agence", "");
                        handleInputChange("date_debut", "");
                        handleInputChange("date_fin", "");
                    }}
                >
                    <CgClose />
                </button>}
            </div>

            <div>
                <DataTable columns={columns.filter(c => c.active)} data={data} />
            </div>
        </div>
    )
}

export default VillesList
