import React, { useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import Input from '../../components/Input';
import Button from '../../components/Button';
import images from '../../constants/images';

const Login = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { login } = useAuth();

    const handleSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        try {
            await login(email, password);
            setIsLoading(false);
        } catch (error) {
            console.log("Failed to login", error);
            setIsLoading(false);
        }
    };

  return (
    <div className="w-full max-w-md p-8 space-y-4 bg-white rounded-lg flex flex-col gap-5">

        <div className='flex flex-col gap-2 border p-4 rounded-lg justify-center items-center'>
            <img src={images.logo} className='w-[80px] object-contain' alt='Logo FABB'/>
            <h2 className="text-md font-mbold text-center">Connexion</h2>
            <p className='text-sm text-center'>Entrez vous informations de connexion pour acceder a votre compte.</p>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
            <Input 
                label="Adresse e-mail"
                type="email" 
                placeholder="Email" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" 
                required 
            />
            <Input 
                label="Mot de passe"
                type="password" 
                placeholder="Password" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" 
                required 
            />
            <Button 
                loading={isLoading}
                type="submit" 
                text="Connexion"
            />
        </form>
    </div>
  )
}

export default Login
