import React from 'react'
import { useAuth } from '../contexts/AuthContext'
import { abbreviateWords } from '../constants/functions';
import { BASE_URL } from '../constants/config';

const Header = () => {

  const { user } = useAuth();

  return (
    <div className='bg-white flex justify-between items-center p-2 border-b h-[56px]'>
      <div></div>

      {/* Information de l'utilisateur connecté */}
      <div className='flex items-center gap-2'>
        <div className='md:flex flex-col items-end gap-0 hidden'>
          <p className='text-sm'>{user?.profil}</p>
          <strong className='font-mmedium text-sm'>{user?.prenom}</strong>
        </div>

        {user?.profil === "super-admin" && <div className='flex bg-primary w-[40px] h-[40px] rounded-full justify-center items-center'>
          <p className='text-light uppercase'>{(user?.nom || user?.prenom) ? abbreviateWords(`${user?.nom ? user?.nom : ''} ${user?.prenom ? user?.prenom : ''}`) : 'FABB CONGO'}</p>
        </div>}

        {user?.profil !== "super-admin" && <div className='flex bg-primary w-[40px] h-[40px] rounded-full justify-center items-center overflow-hidden'>
          {user?.agence_utilisateur?.logo ? <img className='w-full h-full object-cover object-center' src={BASE_URL + "/" + user?.agence_utilisateur?.logo} alt={user?.agence_utilisateur?.nom} /> : <p className='text-light uppercase'>{abbreviateWords(`${user?.nom ? user?.nom : ''} ${user?.prenom ? user?.prenom : ''}`)}</p>}
        </div>}
      </div>
    </div>
  )
}

export default Header