import React, { useEffect, useState } from 'react'
import Input from '../../components/Input';
import SelectInput from '../../components/SelectInput';
import { active_options, jours_options } from '../../constants/values';
import { useNavigate, useParams } from 'react-router-dom';
import { useApp } from '../../contexts/AppContext';
import { useAuth } from '../../contexts/AuthContext';
import { BiPlus } from 'react-icons/bi';
import { CgClose } from 'react-icons/cg';

const PlanningEdit = () => {

    const { user } = useAuth();
    const { villes, update_voyage, lignes, stations, voyages } = useApp();

    const { id } = useParams();

    const voyage = voyages.find(a => a.id.toString() === id);
    const navigate = useNavigate();

    const defaultElement = {

        agence_id: user?.agence_id,
        nombre_de_place: 0,

        jours_voyages: [],

        lignes: [],
        stations: [],

        active: true

    };

    const [isLoading, setIsLoading] = useState(false);
    const [inputs, setInputs] = useState({
        ...voyage, 
        ['lignes']: voyage.lignes?.map((l, _) => {
            return(
                {value: l.id, label: `${l.ville_depart_ligne?.code}-${l.ville_arriee_ligne?.code}`}
            )
        }),
        ['stations']: voyage.stations?.map((s, _) => {
            return(
                {value: s.id, label: `${s.ville_station?.code}-${s.designation}`}
            )
        }),
    } || defaultElement);
    
    const [errors, setErrors] = useState({});

    const handleInputChange = (name, value) => {
        setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
    };

    const handleInputChangeError = (name, value) => {
        setErrors((prevInputs) => ({ ...prevInputs, [name]: value }));
    };

    const validate = () => {
        let valid = true;
        let errors = {};

        if (inputs.lignes.length === 0) {
            errors.lignes = 'Selectionner au moins une ligne';
            valid = false;
        }

        if (inputs.stations.length === 0) {
            errors.stations = 'Selectionner au moins une station';
            valid = false;
        }

        if (inputs.jours_voyages.length === 0) {
            errors.jours_voyages = 'Ajouter au moins un jour de voyage';
            valid = false;
        }

        setErrors(errors);
        return valid;
    };


    const handleSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        try {
            if (validate()) {
                await update_voyage(inputs);

                navigate(-1);
            }
            setIsLoading(false);
        } catch (error) {
            console.log("Failed to login", error);
            setIsLoading(false);
        }
    };

    const handleReset = () => {
        navigate(-1);
    }

    const [lignes_options, setLignesOptions] = useState([]);
    const [stations_options, setStationsOptions] = useState([]);

    const handleAddHoraire = () => {
        setInputs((prev) => ({
            ...prev,
            jours_voyages: [...prev.jours_voyages, { active: true, heure_depart: '', jour: '', nombre_de_place: 0 }]
        }));
    };

    const handleJourChange = (index, field, value) => {
        const updatedJours = inputs.jours_voyages.map((jour, i) =>
            i === index ? { ...jour, [field]: value } : jour
        );
        setInputs((prev) => ({ ...prev, jours_voyages: updatedJours }));
    };

    const handleRemoveHoraire = (index) => {
        const updatedJours = inputs.jours_voyages.filter((_, i) => i !== index);
        setInputs((prev) => ({ ...prev, jours_voyages: updatedJours }));
    };

    useEffect(() => {

        let list = [];

        lignes.map((ligne, _) => {
            list.push({
                value: ligne.id,
                label: `${ligne.ville_depart_ligne?.code}-${ligne.ville_arriee_ligne?.code}`,
                active: ligne.active,
            })
        });

        setLignesOptions(list);
    }, [lignes])

    useEffect(() => {

        let list = [];

        stations.map((station, _) => {
            list.push({
                value: station.id,
                label: `${station.ville_station?.code} - ${station.designation}`,
                active: station.active,
            })
        });

        setStationsOptions(list);
    }, [stations])


    console.log("inputs", JSON.stringify(inputs, null, 2))


    return (
        <div className='flex flex-col w-full items-center'>
            <form onSubmit={handleSubmit} encType="multipart/form-data" className="space-y-4 w-[100%] md:min-w-[700px] bg-white p-2 md:p-4 rounded-lg">

                <div className='border-b pb-4 mb-5'>
                    <h1 className='font-mmedium text-lg'>Enregistrer une voyage</h1>
                    <p className='text-md'>Enregistrer une nouvelle voyage en remplissant le formulaire ci-dessus</p>
                </div>

                <div className='space-y-4'>

                    <div className='flex gap-4 md:gap-2 flex-col md:flex-row'>
                        <SelectInput
                            label="Lignes"
                            value={inputs.lignes}
                            onChange={(e) => handleInputChange('lignes', e.map((e, _) => e))}
                            onFocus={() => handleInputChangeError('lignes', null)}

                            options={lignes_options}
                            error={errors.lignes}
                            isMulti
                            required
                        />
                    </div>

                    <div className='flex gap-4 md:gap-2 flex-col md:flex-row'>
                        <SelectInput
                            label="Stations"
                            value={inputs.stations}
                            onChange={(e) => handleInputChange('stations', e.map((e, _) => e))}
                            onFocus={() => handleInputChangeError('stations', null)}

                            options={stations_options}
                            error={errors.stations}
                            isMulti
                            required
                        />
                    </div>

                    <div className='flex items-center justify-between gap-4 border p-4 rounded-lg'>
                        <div>
                            <h3 className='font-mmedium text-sm'>Jours de voyage</h3>
                            <p className='text-sm font-mlight'>Enregistrer les horaires de voyage pour cette ligne</p>
                        </div>

                        <view onClick={handleAddHoraire} className='flex cursor-pointer bg-primary w-[30px] h-[30px] min-w-[30px] min-h-[30px] rounded-full justify-center items-center text-light text-xl'>
                            <BiPlus />
                        </view>
                    </div>

                    {errors.jours_voyages && <span className='text-[11px] text-red-500'>{errors.jours_voyages}</span>}

                    {inputs.jours_voyages?.map((jour, index) => (
                        <div className='flex items-center justify-between gap-4' key={index}>
                            <div className='flex flex-1 gap-4 md:gap-2 flex-col md:flex-row'>
                                <SelectInput
                                    label="Jour"
                                    value={jour.jour}  // Utilise l'index pour obtenir le jour spécifique
                                    onChange={(e) => handleJourChange(index, 'jour', e.value)}
                                    options={jours_options}
                                    error={errors.jour}
                                    required
                                />

                                <Input
                                    label="Heure de départ"
                                    type="time"
                                    value={jour.heure_depart}
                                    onChange={(e) => handleJourChange(index, 'heure_depart', e.target.value)}
                                    error={errors.heure_depart}
                                    required
                                />

                                <Input
                                    label="Nombre de réservation possible"
                                    type="number"
                                    value={jour.nombre_de_place}
                                    onChange={(e) => handleJourChange(index, 'nombre_de_place', e.target.value)}
                                    error={errors.nombre_de_place}
                                    required
                                />

                                <SelectInput
                                    label="Actif"
                                    value={jour.active}
                                    onChange={(e) => handleJourChange(index, 'active', e.value)}
                                    options={active_options}
                                    required
                                />
                            </div>

                            {!jour?.id && <div onClick={() => handleRemoveHoraire(index)} className='flex justify-center items-center h-[50px] text-xl text-primary cursor-pointer'>
                                <CgClose />
                            </div>}
                        </div>
                    ))}


                </div>

                <div className='flex justify-end mt-5 gap-2'>
                    <button onClick={handleReset} className='flex-1 md:flex-none border border-primary rounded-lg px-4 py-1 text-primary text-md'>Annuler</button>
                    <button type='submit' className='flex-1 md:flex-none border border-primary bg-primary rounded-lg px-4 py-1 text-light text-md' disabled={isLoading}>{!isLoading ? 'Enregister' : 'Loading ...'}</button>
                </div>
            </form>
        </div>
    )
}

export default PlanningEdit

