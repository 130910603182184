import React, { useEffect, useState } from 'react'
import Input from '../../components/Input';
import SelectInput from '../../components/SelectInput';
import { active_options, type_agence_options } from '../../constants/values';
import Textarea from '../../components/Textarea';
import { useNavigate, useParams } from 'react-router-dom';
import { useApp } from '../../contexts/AppContext';
import InputFile from '../../components/InputFile';
import { BiCamera } from 'react-icons/bi';
import { useAuth } from '../../contexts/AuthContext';

const StationEdit = () => {
  const { stations, villes, update_station } = useApp();
  const { user } = useAuth();
  const { id } = useParams();

  const station = stations.find(a => a.id.toString() === id);

  const defaultElement = {
    designation: "",
    agence_id: user?.agence_id,
    ville_id: "",
    telephone: "",
    adresse: "",
    active: true
  };

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState(station || defaultElement);

  const [errors, setErrors] = useState({});

  const handleInputChange = (name, value) => {
    setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  const handleInputChangeError = (name, value) => {
    setErrors((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  const validate = () => {
    let valid = true;
    let errors = {};

    if (!inputs.designation) {
      errors.designation = 'La designation est obligatoire';
      valid = false;
    } else if (/[^a-zA-Z0-9\s]/.test(inputs.designation)) {
      errors.designation = 'La designation ne doit pas contenir de caractères spéciaux';
      valid = false;
    }

    if (!inputs.ville_id) {
      errors.ville_id = 'La ville est obligatoire';
      valid = false;
    }

    if (!inputs.telephone) {
      errors.telephone = 'Le numéro de téléphone est obligatoire';
      valid = false;
    }


    setErrors(errors);
    return valid;
  };


  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      if (validate()) {
        await update_station(inputs);

        navigate(-1);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("Failed to login", error);
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    navigate(-1);
  }

  const [villes_options, setVilleOptions] = useState([]);

  useEffect(() => {

    let list = [];

    villes.map((ville, _) => {
      list.push({
        value: ville.id,
        label: ville.nom
      })
    });

    setVilleOptions(list);
  }, [villes])

  return (
    <div className='flex flex-col w-full items-center'>
      <form onSubmit={handleSubmit} encType="multipart/form-data" className="space-y-4 w-[100%] md:min-w-[700px] bg-white p-2 md:p-4 rounded-lg">

        <div className='border-b pb-4 mb-5'>
          <h1 className='font-mmedium text-lg'>Enregistrer une station</h1>
          <p className='text-md'>Enregistrer une nouvelle station en remplissant le formulaire ci-dessus</p>
        </div>

        <div className='space-y-4'>
          <div className='flex gap-4 md:gap-2 flex-col md:flex-row'>
            <Input
              label="Designation"
              type="text"
              placeholder="Designation"
              value={inputs.designation}
              error={errors.designation}
              onChange={(e) => handleInputChange('designation', e.target.value)}
              onFocus={() => handleInputChangeError('designation', null)}

              required
            />

            <SelectInput
              label="Active"
              value={inputs.active}
              onChange={(e) => handleInputChange('active', e.value)}
              onFocus={() => handleInputChangeError('active', null)}

              options={active_options}
              error={errors.active}
              required
            />
          </div>


          <div className='flex gap-4 md:gap-2 flex-col md:flex-row'>

            <SelectInput
              label="Ville"
              value={inputs.ville_id}
              onChange={(e) => handleInputChange('ville_id', e.value)}
              onFocus={() => handleInputChangeError('ville_id', null)}

              options={villes_options}
              error={errors.ville_id}
              required
            />

            <Input
              label="Adresse"
              type="text"
              placeholder="Adresse de l'agence"
              value={inputs.adresse}
              error={errors.adresse}
              onChange={(e) => handleInputChange('adresse', e.target.value)}
              onFocus={() => handleInputChangeError('adresse', null)}

            />

            <Input
              label="Téléphone"
              type="text"
              placeholder="Numéro de téléphone"
              value={inputs.telephone}
              error={errors.telephone}
              onChange={(e) => handleInputChange('telephone', e.target.value)}
              onFocus={() => handleInputChangeError('telephone', null)}

            />
          </div>

        </div>

        <div className='flex justify-end mt-5 gap-2'>
          <button onClick={handleReset} className='flex-1 md:flex-none border border-primary rounded-lg px-4 py-1 text-primary text-md'>Annuler</button>
          <button type='submit' className='flex-1 md:flex-none border border-primary bg-primary rounded-lg px-4 py-1 text-light text-md' disabled={isLoading}>{!isLoading ? 'Enregister' : 'Loading ...'}</button>
        </div>
      </form>
    </div>
  )
}

export default StationEdit

